import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { createGuid } from '../../../core/helpers/utility';
import { INotes } from '../../../core/models/campaigns.model';
import { ISalesRep } from '../../../core/models/datafeed.models';
import { ITask } from '../../../core/models/tasks.models';
import { DataFeedService } from '../../api/datafeed.service';
import { TasksApiService } from '../../../pages/tasks/tasks.apiservice';
import { ROLES_TYPE } from '../../../core/helpers/constants';
import { Utility } from '../../utility';

@Component({
  selector: 'modal-add-note',
  templateUrl: './add-campaigncheck.component.html',
  styleUrls: ['./add-campaigncheck.component.scss']
})
export class AddCampaignCheckModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allAssignees: Array<ISalesRep> = new Array<ISalesRep>();
  tasksDataNotCompleted: Array<ITask> = new Array<ITask>();

  addCampaignCheckForm!: FormGroup;
  
  submitted = false;

  @Input() iClientId: string = '';
  @Input() iCampaignId: string = '';
  @Input() iLeadId: string = '';
  @Input() iType: number = 1;
  @Input() iActiveUserExternal: number = ROLES_TYPE.External;
  @Input() iIsAdmin: boolean = true;
  @Input() isGooglePlatform: boolean = true;
  @Input() set iNote(note: INotes) {
    if (note) {
      this.addCampaignCheckForm.patchValue(note);
      if (note.noteFiles)
        this.files = note.noteFiles;
    }
  }

  constructor(
    private dataFeedService: DataFeedService,
    private taskApiService: TasksApiService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.addCampaignCheckForm = this.formBuilder.group({
      id: [null],
      type: [null],
      campaignId: [null],
      clientId: [null],
      leadId: [null],
      impression: [null, [Validators.required]],
      leads: [null, [Validators.required]],
      cpl: [null, [Validators.required]],
      ctr: [null, [Validators.required]],
      cPMOrCPC: [null, [Validators.required]],
      lFSROrLPCR: [null, [Validators.required]],
      taskIdToCompleted: [''],
      insightText: [null],
      changesText: [null],
      isQCCheck: [null],
      isRedAlert: [null],
      addTaskDate:[null, [Validators.required]]
    });

    // this.activeUserId = util.getActiveUser().id;
    // if (this.activeUserId) {
    //   this.addCampaignCheckForm.patchValue({
    //     assignedUserId: this.activeUserId
    //   });
    // }
    this.loadDetails();
  }
  get f() { return this.addCampaignCheckForm.controls; }

  activeUserId = '';
  assignedUserFullname = '';

  ngOnInit() {
    this.taskApiService.getTasksByCampaign(this.iCampaignId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.tasksDataNotCompleted = [...response.data.filter((x: ITask) => x.isCompleted === null || x.isCompleted === false)];
        });
  }
  loadDetails() {
    
  }

  onSubmit() {
    this.submitted = true;
    if (this.addCampaignCheckForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid());
    var request = this.addCampaignCheckForm.value;
    request.campaignId = this.iCampaignId;
    request.clientId = this.iClientId;
    request.leadId = this.iLeadId;
    request.isGooglePlatform = this.isGooglePlatform;
    request.type = this.iType;
    request.addFiles = this.files.filter(x => x.link === undefined).map(x => { return { name: x.name, content: x.content } });
    request.removeFiles = this.removedFiles;
    request.addTaskDate = Utility.getDateFromDateTime(request.addTaskDate);
    this.oSaveClicked.emit(request);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  files: any[] = [];
  removedFiles: any[] = [];
  onSelect(event: any) {
    for (var i = 0; i < event.addedFiles.length; i++) {
      var file = event.addedFiles[i];
      var that = this.files;
      this.readFile(file).then((fileContents: any) => {
        file.content = fileContents;
        that.push(file);
      })
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
    if (event.id) {
      this.removedFiles.push(event.id);
    }
  }

  readFile(file: File): any {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        var result: any = (e.target as FileReader).result;
        return resolve(result);
      };
      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };
      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }
      reader.readAsDataURL(file);
    });
  }
}