<ng-container *ngFor="let category of result | keyvalue">
    <mat-card class="card">
        <mat-card-title class="title">
            {{category.key}}
            <hr>
        </mat-card-title>
        <mat-card-content class="body" ngPreserveWhitespaces>
            <app-datatable [iCanRead]="true" [iCanWrite]="true" [iColumns]="columns[category.key]" [iData$]="category.value" [iShowActions]="false"
                [iShowHeader]="false" [iShowSelect]="false" (oRowClick)="onRowClick($event)"></app-datatable>
        </mat-card-content>
    </mat-card>
</ng-container>

<ng-container *ngIf="result.size === 0">
    <mat-card class="card">
        <mat-card-title class="title">
            No Results Found for {{keyword}}
            <hr>
        </mat-card-title>
        <mat-card-content class="body" ngPreserveWhitespaces>
            <app-datatable [iCanRead]="true" [iCanWrite]="true" [iShowActions]="false" [iShowHeader]="false" [iShowSelect]="false"></app-datatable>
        </mat-card-content>
    </mat-card>
</ng-container>