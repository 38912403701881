import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ROLES } from '../../../core/helpers/constants';
import { createEmptyGuid, createGuid } from '../../../core/helpers/utility';
import { IClient } from '../../../core/models/clients.models';
import { ICompany, ISalesRep, IUser } from '../../../core/models/datafeed.models';
import { ClientsApiService } from '../../../pages/clients/clients.apiservice';
import { AppService } from '../../../store/app.service';
import { DataFeedService } from '../../api/datafeed.service';

@Component({
  selector: 'modal-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientModal implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allCompanies: Array<ICompany> = new Array<ICompany>();
  allSalesReps: Array<ISalesRep> = new Array<ISalesRep>();
  allDms: Array<ISalesRep> = new Array<ISalesRep>();
  allEmployees: Array<IUser> = new Array<IUser>();
  allSubEmployees: Array<IUser> = new Array<IUser>();
  allClientOwners: Array<IUser> = new Array<IUser>();
  client: any;
  addClientForm!: FormGroup;
  submitted = false;
  isProspect = false;

  @Input() set iId(value: number) {
    this.id = value;
    if (value !== null) {
      this.clientApiService.getClientDetails(value)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.client = response.data;
          this.addClientForm.patchValue(response.data);
          this.addClientForm.patchValue({ employees: response.data.employees.map((x: any) => ({ id: x.id, fullName: `${x.firstName} ${x.lastName}` })) });
        });
    }
  }
  id: number = -1;

  constructor(
    private dataFeedService: DataFeedService,
    private clientApiService: ClientsApiService,
    private appService: AppService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.addClientForm = this.formBuilder.group({
      id: [null],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      brandAddress: [''],
      brandId: [null],
      dmsId: [null],
      salesRepId: [null],
      clientOwnerId: [null],
      employees: [null],
      subEmployeeIds: [''],
      isProspect: [false],
      phone: [''],
      email: ['', [Validators.required, Validators.email]],
    });
  }
  get f() { return this.addClientForm.controls; }

  updateData(data: IClient) {
    this.addClientForm.patchValue(data);
  }

  ngOnInit() {
    this.appService.getActiveRoute$()
      .subscribe((route: any) => {
        if (route.url.indexOf('prospect') > -1 || route.url.indexOf('prospect-details') > -1)
          if (this.id === null)
          {
            this.addClientForm.patchValue({ isProspect: true });
            this.isProspect = true;
          }
           
      });
  }
  ngAfterViewInit() {
    this.dataFeedService.getAllCompanies()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allCompanies = [...response.data];
        }
      });

    this.dataFeedService.getAllUsersWithRole(ROLES.SalesRep.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allSalesReps = [...response.data];
        }
      });

    this.dataFeedService.getAllUsersWithRole(ROLES.DMS.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allDms = [...response.data];
        }
      });

    this.dataFeedService.getAllUsersWithRole(ROLES.ClientOwner.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allClientOwners = [...response.data];
        }
      });

  }
  onSubmit() {
    this.submitted = true;
    if (this.addClientForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())

    this.oSaveClicked.emit(this.addClientForm.value);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
