<div>
    <form *ngIf="iProductFamily === PRODUCT_FAMILY.DigitalMarketing"
        [formGroup]="formsMap.get(PRODUCT_FAMILY.DigitalMarketing)!"
        (ngSubmit)="onSubmit(PRODUCT_FAMILY.DigitalMarketing)">

        <div class="row mb-10">
            <div [ngClass]="{'col-md-4':!isNewCampaign,'col-md-6':isNewCampaign}">
                <div class="row" fxLayout="row" fxLayoutAlign="center center">
                    <label class="col-md-4">Client</label>
                    <div class="col-md-8">
                        <ng-select [clearable]="false"
                            [ngClass]="{'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.clientId.errors }"
                            bindLabel="fullName" bindValue="id" [items]="allMyClients" formControlName="clientId">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div *ngIf="!isNewCampaign" class="col-md-4">
                <div class="row" fxLayout="row" fxLayoutAlign="center center">
                    <label class="col-md-4">Campaign</label>
                    <div class="col-md-8">
                        <ng-select
                            [ngClass]="{'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.campaignId.errors}"
                            formControlName="campaignId" bindLabel="name" bindValue="id" [items]="myCampaigns">
                        </ng-select>
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.campaignId.errors"
                            class="invalid-feedback">
                            <div *ngIf="fDigiForm.campaignId.errors?.required">Please select a campaign
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="{'col-md-4':!isNewCampaign,'col-md-6':isNewCampaign}">
                <div *ngIf="!isNewCampaign">
                    <button style=" width: 200px; float: right;" (click)="onAddCampaign(true)"
                        class="btn btn-page-orange">Add
                        New Campaign</button>
                </div>
                <div *ngIf="isNewCampaign">
                    <button (click)="onAddCampaign(false)" style=" width: 200px; float: right;"
                        class="btn btn-page-orange">Show
                        Existing Campaigns</button>
                </div>
            </div>
        </div>
        <div *ngIf="iProduct.canCustomerChoose && iProduct.isGoogle && iProduct.isFacebook" fxLayout="row"
            class="row mb-10">
            <div class="col-md-6">
                <div fxLayout="row" fxLayoutAlign="center center" class="row">
                    <div class="col-md-4">
                        <label>Marketing Platforms</label>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-2">
                                <input type="checkbox" id="isGoogle" formControlName="isGoogle"
                                    class="form-check-input" />
                            </div>
                            <label class="col-sm-8" for="isGoogle">Google</label>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <input type="checkbox" id="isFacebook" formControlName="isFacebook"
                                    class="form-check-input" />
                            </div>
                            <label class="col-sm-8" for="isFacebook">Meta (Facebook/Instagram)</label>
                        </div>

                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="isNewCampaign">
            <div class="row mb-10">
                <div class="col-md-6">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-4">
                            <label>Brand Name</label>
                        </div>
                        <div class="col-md-8">
                            <input (change)="onNameChange($event)"
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.brandName.errors }"
                                type="text" formControlName="brandName" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.brandName.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.brandName.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-4">
                            <label>Studio Location Name</label>
                        </div>
                        <div class="col-md-8">
                            <input (change)="onNameChange($event)"
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioLocationName.errors }"
                                type="text" formControlName="studioLocationName" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioLocationName.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.studioLocationName.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-10">
                <div class="col-md-12">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-2">
                            <label>Campaign Name</label>
                        </div>
                        <div class="col-md-10">
                            <input readonly
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.campaignName.errors }"
                                type="text" formControlName="campaignName" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.campaignName.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.campaignName.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-10">
                <div class="col-md-6">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-4">
                            <label>Studio Email Address</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioEmail.errors }"
                                type="text" formControlName="studioEmail" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioEmail.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.studioEmail.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div fxLayoutAlign="center center" class="row" class="row">
                        <div class="col-md-4">
                            <label>Studio Phone #</label>
                        </div>
                        <div class="col-md-8">
                            <input
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioPhone.errors }"
                                type="text" formControlName="studioPhone" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioPhone.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.studioPhone.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-10">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Studio Address</label>
                        </div>
                        <div class="col-md-12">
                            <input
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioAddress.errors }"
                                type="text" formControlName="studioAddress" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.studioAddress.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.studioAddress.errors.required">required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-10">
                <div class="col-md-12">
                    <label>If you selected facebook above, please provide the facebook page url for this
                        location</label>
                </div>
                <div class="col-md-12">
                    <input
                        [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.fbPageUrl.errors }"
                        type="text" formControlName="fbPageUrl" class="form-control" />
                    <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.fbPageUrl.errors"
                        class="invalid-feedback">
                        <div *ngIf="fDigiForm.fbPageUrl.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-10">
                <div class="col-md-6">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-4">
                            <label>CRM Name </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.crmName.errors }"
                                type="text" formControlName="crmName" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.crmName.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.crmName.errors.required">CRM name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div fxLayout="row" fxLayoutAlign="center center" class="row">
                        <div class="col-md-4">
                            <label>CRM ID </label>
                        </div>
                        <div class="col-md-8">
                            <input
                                [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.crmId.errors }"
                                type="text" formControlName="crmId" class="form-control" />
                            <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.crmId.errors"
                                class="invalid-feedback">
                                <div *ngIf="fDigiForm.crmId.errors.required">CRM ID is required</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="row mb-10">
                <div class="col-md-12">
                    <label>Notes</label>
                </div>
                <div class="col-md-12">
                    <textarea type="text" rows="3" class="form-control" formControlName="notes"
                        [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.notes.errors }"
                        placeholder="Enter note"> </textarea>
                    <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.DigitalMarketing) && fDigiForm.notes.errors"
                        class="invalid-feedback">
                        <div *ngIf="fDigiForm.notes.errors.required">note is required</div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form *ngIf="iProductFamily === PRODUCT_FAMILY.AddPlatform"
        [formGroup]="formsMap.get(PRODUCT_FAMILY.AddPlatform)!"
        (ngSubmit)="onSubmit(PRODUCT_FAMILY.AddPlatform)">

        <div class="row mb-10">
            <div class="col-md-6">
                <div class="row" fxLayout="row" fxLayoutAlign="center center">
                    <label class="col-md-4">Client</label>
                    <div class="col-md-8">
                        <ng-select [clearable]="false"
                            [ngClass]="{'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.AddPlatform) && fAddPlatformForm.clientId.errors}"
                            bindLabel="fullName" bindValue="id" [items]="allMyClients" formControlName="clientId">
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row" fxLayout="row" fxLayoutAlign="center center">
                    <label class="col-md-4">Campaign</label>
                    <div class="col-md-8">
                        <ng-select
                            [ngClass]="{'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.AddPlatform) && fAddPlatformForm.campaignId.errors}"
                            formControlName="campaignId" bindLabel="name" bindValue="id" [items]="myCampaigns">
                        </ng-select>
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.AddPlatform) && fAddPlatformForm.campaignId.errors"
                            class="invalid-feedback">
                            <div *ngIf="fAddPlatformForm.campaignId.errors?.required">Please select a campaign
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="showAPGoogle || showAPFacebook" fxLayout="row"
            class="row mb-10">
            <div class="col-md-6">
                <div fxLayout="row" fxLayoutAlign="center center" class="row">
                    <div class="col-md-4">
                        <label>Marketing Platforms</label>
                    </div>
                    <div class="col-md-8">
                        <div *ngIf="showAPGoogle" class="row">
                            <div class="col-sm-2">
                                <input type="checkbox" id="isGoogle" formControlName="isGoogle"
                                    class="form-check-input" />
                            </div>
                            <label class="col-sm-8" for="isGoogle">Google</label>
                        </div>
                        <div *ngIf="showAPFacebook" class="row">
                            <div class="col-sm-2">
                                <input type="checkbox" id="isFacebook" formControlName="isFacebook"
                                    class="form-check-input" />
                            </div>
                            <label class="col-sm-8" for="isFacebook">Meta (Facebook/Instagram)</label>
                        </div>

                    </div>
                </div>
            </div>

        </div> -->
    </form>


    <form *ngIf="iProductFamily === PRODUCT_FAMILY.CampaignConsulting"
        [formGroup]="formsMap.get(PRODUCT_FAMILY.CampaignConsulting)!"
        (ngSubmit)="onSubmit(PRODUCT_FAMILY.CampaignConsulting)">
        <div class="row mb-10">
            <div class="col-md-12">
                <label>Notes</label>
            </div>
            <div class="col-md-12">
                <textarea type="text" rows="3" class="form-control" formControlName="notes"
                    [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.CampaignConsulting) && fCampaignConsultingForm.notes.errors }"
                    placeholder="Enter note"> </textarea>
                <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.CampaignConsulting) && fCampaignConsultingForm.notes.errors"
                    class="invalid-feedback">
                    <div *ngIf="fCampaignConsultingForm.notes.errors.required">note is required</div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="iProductFamily === PRODUCT_FAMILY.SEOProgram" [formGroup]="formsMap.get(PRODUCT_FAMILY.SEOProgram)!"
        (ngSubmit)="onSubmit(PRODUCT_FAMILY.SEOProgram)">

        <div class="row mb-10">
            <div class="col-md-12">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row" class="row">
                    <div class="col-md-2">
                        <label>Business Address</label>
                    </div>
                    <div class="col-md-10">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessAddress.errors }"
                            type="text" formControlName="businessAddress" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessAddress.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSEOForm.businessAddress.errors.required">Business address is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-md-6">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row" class="row">
                    <div class="col-md-4">
                        <label>Business Email Address</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessEmail.errors }"
                            type="email" formControlName="businessEmail" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessEmail.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSEOForm.businessEmail.errors.required">Business email is required</div>
                            <div *ngIf="fSEOForm.businessEmail.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row" class="row">
                    <div class="col-md-4">
                        <label>Business Phone </label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessPhone.errors }"
                            type="text" formControlName="businessPhone" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessPhone.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSEOForm.businessPhone.errors.required">Business phone is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-6">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row" class="row">
                    <div class="col-md-4">
                        <label>Business Name</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessName.errors }"
                            type="text" formControlName="businessName" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessName.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSEOForm.businessName.errors.required">Business name is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-6">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row" class="row">
                    <div class="col-md-4">
                        <label>Website Url</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessWebsiteUrl.errors }"
                            type="text" formControlName="businessWebsiteUrl" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SEOProgram) && fSEOForm.businessWebsiteUrl.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSEOForm.businessWebsiteUrl.errors.required">Website is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="iProductFamily === PRODUCT_FAMILY.HostedWebsite"
        [formGroup]="formsMap.get(PRODUCT_FAMILY.HostedWebsite)!" (ngSubmit)="onSubmit(PRODUCT_FAMILY.HostedWebsite)">

        <div class="row" class="row mb-10">
            <div class="col-md-12">
                <label>Website Info</label>
            </div>
            <div class="col-md-12">
                <textarea type="text" rows="3" class="form-control" formControlName="websiteInfo"
                    [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.HostedWebsite) && fHostedWebsiteForm.websiteInfo.errors }"
                    placeholder="Enter website info"> </textarea>
                <span style="font-size:10px">Please provide your URL (if you have one)</span>
                <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.HostedWebsite) && fHostedWebsiteForm.websiteInfo.errors"
                    class="invalid-feedback">
                    <div *ngIf="fHostedWebsiteForm.websiteInfo.errors.required">note is required</div>
                </div>
            </div>
        </div>

        <div class="row mb-10">
            <div class="col-md-12">
                <label>Notes</label>
            </div>
            <div class="col-md-12">
                <textarea type="text" rows="3" class="form-control" formControlName="notes"
                    [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.HostedWebsite) && fHostedWebsiteForm.notes.errors }"
                    placeholder="Enter note"> </textarea>
                <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.HostedWebsite) && fHostedWebsiteForm.notes.errors"
                    class="invalid-feedback">
                    <div *ngIf="fHostedWebsiteForm.notes.errors.required">note is required</div>
                </div>
            </div>
        </div>
    </form>

    <form *ngIf="iProductFamily === PRODUCT_FAMILY.SocialMedia" [formGroup]="formsMap.get(PRODUCT_FAMILY.SocialMedia)!"
        (ngSubmit)="onSubmit(PRODUCT_FAMILY.SocialMedia)">


        <div class="row mb-10">
            <div class="col-md-12">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row">
                    <div class="col-md-4">
                        <label>Website Url</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.websiteUrl.errors }"
                            type="text" formControlName="websiteUrl" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.websiteUrl.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSocialMediaForm.websiteUrl.errors.required">required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-12">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row">
                    <div class="col-md-4">
                        <label>Instagram Url</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.instagramUrl.errors }"
                            type="text" formControlName="instagramUrl" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.instagramUrl.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSocialMediaForm.instagramUrl.errors.required">required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-10">
            <div class="col-md-12">
                <div fxLayoutAlign="row" fxLayoutAlign="center center" class="row">
                    <div class="col-md-4">
                        <label>Facebook Url</label>
                    </div>
                    <div class="col-md-8">
                        <input
                            [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.facebookUrl.errors }"
                            type="text" formControlName="facebookUrl" class="form-control" />
                        <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.facebookUrl.errors"
                            class="invalid-feedback">
                            <div *ngIf="fSocialMediaForm.facebookUrl.errors.required">required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mb-10">
            <div class="col-md-12">
                <label>Additional Info / Links</label>
            </div>
            <div class="col-md-12">
                <textarea type="text" rows="3" class="form-control" formControlName="notes"
                    [ngClass]="{ 'is-invalid': formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.notes.errors }"
                    placeholder="Enter note"> </textarea>
                <div *ngIf="formsSubmittedMap.get(PRODUCT_FAMILY.SocialMedia) && fSocialMediaForm.notes.errors"
                    class="invalid-feedback">
                    <div *ngIf="fSocialMediaForm.notes.errors.required">note is required</div>
                </div>
            </div>
        </div>
    </form>
</div>