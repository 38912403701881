<div class="row">
    <div class="col-12">
        <div class="card card-custom" style="border: none !important; border-radius: 0px !important; padding: 16px; margin-bottom: 16px !important;">
            <form [formGroup]="dtForm" *ngIf="iShowHeader">
                <div class="card-header">
                    <div class="d-flex flex-md-row flex-lg-row flex-sm-row justify-content-between align-items-center">
                        <div class="flex-row" style="display: flex; align-items: center;">
                            <div *ngIf="iShowSearch" style="display: flex; flex-direction: row; align-items: center; padding: 8px 4px 8px 8px; border: 1px solid grey; border-radius: 16px; min-width: 265px; background-color: white; max-height: 34px !important;">
                                <i-feather name="search" style="width: 16px; height: 16px; color: grey;"></i-feather>
                                <input id="txtSearchQuery" type="text" formControlName="searchQuery" [placeholder]="(isCompleted) ? 'Search Completed' : 'Search TO-DO'" class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table" style="border: none !important; background-color: white !important; max-height: 15px; max-width: 220px;"
                                    (keydown.enter)="doServerSearch($event)">
                            </div>
                            <button *ngIf="activePage===PAGE_NAMES.Tasks" type="button" class="blue-button" style="margin-left: 20px;" (click)="onAddTaskClick('add')">ADD TASK</button>
                            <div
                                *ngIf="(iShowActions && !iHideHeaderActions) && (isAdmin || iCanDelete || iShowExport) && activePage !== PAGE_NAMES.Users">
                                <div *ngIf="isAdmin || iCanDelete || iShowMerge" ngbDropdown
                                    class="d-inline-block dropdown-custom">
                                    <button style="margin-right: 20px;" class="btn btn-dd" id="dropdownBasic1"
                                        ngbDropdownToggle>Actions</button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                        <button *ngIf="(isAdmin || iCanDelete) && iShowDropDownDelete"
                                            (click)="deleteRows()" ngbDropdownItem>Delete</button>

                                        <button *ngIf="iShowMerge && checkedItemsCount >= 2" type="button"
                                            ngbDropdownItem (click)="mergeRecords()"
                                            title="Please select two or more records to merge!">Merge
                                        </button>
                                    </div>
                                </div>
                                <div class="d-inline-block">
                                    <button *ngIf="iShowExport" type="button" class="btn btn-outline-dark button"
                                        style="padding-top: 3px;padding-bottom: 3px;"
                                        (click)="exportToCsv()">Export</button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="iShowDateRange" class="d-flex flex-row align-items-center">
                                    <label class="d-inline-flex align-items-center">From: </label>&nbsp;
                                    <input id="dtStartDate"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="startDate" class="form-control" />
                                        &nbsp;&nbsp;&nbsp;
                                    <label class="d-inline-flex align-items-center">To: </label>&nbsp;
                                    <input id="dtEndDate"
                                        mwlFlatpickr [altInputClass]="'datetime'" [altInput]="true"
                                        [convertModelValue]="true" formControlName="endDate" class="form-control" /><a (click)="clearDates()"><i-feather name="x-circle" style="margin: 5px 0px 0px 5px; cursor: pointer;"></i-feather></a>
                        </div>
                        <div class="d-flex justify-content-between align-items-center true-flex hide-from-small" style="justify-content: flex-end !important;">
                            <select style="border-radius: 16px; margin-right: 32px; max-width: 200px;" name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                formControlName="pageSize" (change)="onPageSizeChange($event)" [(ngModel)]="iPageSize"
                                class="form-control form-select select-custom">
                                <option [value]="10">10 Per Page</option>
                                <option [value]="25">25 Per Page</option>
                                <option [value]="50">50 Per Page</option>
                                <option [value]="100">100 Per Page</option>
                                <option [value]="250">250 Per Page</option>
                                <option [value]="500">500 Per Page</option>
                            </select>
                            <div class="text-md-right float-md-end pagination-rounded">
                                <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                                    [collectionSize]="collectionSize" [page]="pageNumber"  [boundaryLinks]="true"
                                    (pageChange)="onPageChange($event)" [pageSize]="f.pageSize.value">
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="card-body">
                <table cellspacing="0" cellpadding="0" id="datatable" class="table dt-responsive nowrap w-100">
                    <thead>
                        <tr>
                            <th *ngIf="iShowSelect  && (isAdmin || iCanDelete || iCanWrite)">
                                <div class="form-check">
                                    <input (change)="onCbHeadChange($event)" class="form-check-input" type="checkbox">
                                </div>
                            </th>
                            <ng-container *ngFor="let col of iColumns; let i=index">
                                <th *ngIf="col.visible === undefined || col.visible" dtSortable
                                    [attr.sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    [sortable]="(col.sortable  && col.sortable)? col.field : null"
                                    (sort)="onSort($event, col)">
                                    {{col.title}}
                                    <ng-container *ngIf="col.title === '' && col.field === 'media'">
                                        <i class="far fa-image fa-2x"></i>
                                    </ng-container>
                                    <ng-container *ngIf="col.title === '' && col.field === 'html'">
                                        <i class="fas fa-code fa-2x"></i>
                                    </ng-container>
                                </th>
                            </ng-container>
                            <th *ngIf="(iShowActions || iShowViewAction) && (iCanWrite || iCanDelete)">
                                Actions
                            </th>
                            <th *ngIf="!((iShowActions || iShowViewAction) && (iCanWrite || iCanDelete))"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="viewData.length==0">
                            <td style="text-align: center;"
                                [attr.colspan]="(iColumns.length + 2)">
                                No data found
                            </td>
                        </tr>
                        <tr [style.color]="row.isDeleted ? 'red' : null" *ngFor="let row of viewData; let i=index">
                            <td *ngIf="iShowSelect && (isAdmin || iCanDelete || iCanWrite)" class="show-check">
                                <div class="form-check">
                                    <input [(ngModel)]="row.isSelected" (change)="onCbChange($event,row)"
                                        (click)="$event.stopPropagation();" class="form-check-input" type="checkbox">
                                </div>
                            </td>
                            <td *ngFor="let col of iColumns; let j=index" style="padding: 0px;height: 30px;">
                                <p class="task-label" style="margin-bottom: 0px !important;">{{col.title}}: </p>
                                <a *ngIf="(col.visible === undefined || col.visible )"
                                    style="color: inherit ;display: block;padding: 10px;line-height: 30px;"
                                    [ngStyle]="{'color' : (col.field === 'dueDate') ? ((checkDueDate(row)) ? '#f39437' : 'white') : 'black', 'background-color' : (col.field === 'dueDate') ? ((checkDueDate(row)) ? '#fdf2be' : 'red') : 'transparent', 'border-radius' : (col.field === 'dueDate') ? '50px' : '0px', 'text-align' : (col.field === 'dueDate') ? 'center' : '', 'padding' : (col.field === 'dueDate') ? '1px' : '10px', 'max-width' : (col.field === 'dueDate') ? '100px' : 'none'}"
                                    (click)="oRowClick.emit(row)" [routerLink]="getRowLink(row).routerLink"
                                    [queryParams]="getRowLink(row).queryParams" role="link">
                                    <div *ngIf="col.anchorLink && col.anchorLink; else html">
                                        <a style="color: #2563eb;" [routerLink]="getAnchorLink(col.anchorType, row[col.anchorParamsField] )">
                                            {{row[col.field]}}
                                        </a>
                                    </div>
                                    <ng-template #html>
                                        <div *ngIf="col.type === 'html'; else image"
                                            [innerHTML]="SafeHtml(row[col.field])">
                                        </div>
                                    </ng-template>
                                    <ng-template #image>
                                        <div *ngIf="col.type === 'image'; else normal">
                                            <img [src]="SafeUrl(extractFieldValue(row, col.field,col.pipe, col?.pipeArgs))"
                                                style="height: 25px;" />
                                        </div>
                                    </ng-template>
                                    <ng-template #normal>
                                        <ngb-highlight
                                            [result]="extractFieldValue(row, col.field, col.pipe, col?.pipeArgs)">
                                        </ngb-highlight>
                                    </ng-template>
                                </a>
                            </td>
                            <td
                                *ngIf="( iShowActions || iShowViewAction )  && (iCanWrite || iCanDelete || iCanRead || true)" class="hide-from-small">
                                <a *ngIf="iShowEditAction && (isAdmin || (iCanWrite && (row.canBeEdit == null || row.canBeEdit == true)) )" class="a-btn"
                                    style="margin-right: 10px;" (click)="editRow(row);$event.stopPropagation();">
                                    <i-feather name="edit"></i-feather>
                                </a>
                                <a *ngIf="iShowViewAction && (isAdmin || iCanRead)" class="a-btn"
                                    style="margin-right: 10px;" (click)="viewRow(row);$event.stopPropagation();"
                                    title="View">
                                    <i-feather name="eye"></i-feather>
                                </a>
                                <a *ngIf="iShowExternalAction && (isAdmin || iCanRead)" class="a-btn"
                                    style="margin-right: 10px;" (click)="showExternal(row);$event.stopPropagation();"
                                    title="Opens on new page">
                                    <i-feather name="external-link"></i-feather>
                                </a>
                                <a *ngIf="showDeleteAction(row) && iShowDeleteAction && (isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id && row?.archived == true : true)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onDeleteRow($event,row);$event.stopPropagation();">
                                    <i-feather name="trash"></i-feather>
                                </a>
                                <a *ngIf="(isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id  && row?.archived == true : false)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onUnArchiveRow($event,row);$event.stopPropagation();">
                                    <mat-icon title="UnArchive">unarchive</mat-icon>
                                </a>
                                <a *ngIf="(isAdmin || iCanDelete) && ( activePage === PAGE_NAMES.Users ? iActiveUserId !== row?.id  && row?.archived == false : false)"
                                    class="a-btn" style="margin-right: 10px;"
                                    (click)="onArchiveRow($event,row);$event.stopPropagation();">
                                    <mat-icon title="Archive">archive</mat-icon>
                                </a>
                                <a *ngIf="iShowSendEmail" title="Email" class="a-btn" style="margin-right: 10px;"
                                    (click)="onSendEmail(row);$event.stopPropagation();">
                                    <i-feather name="mail"></i-feather>
                                </a>
                                <a *ngIf="iShowMakeCopyAction && iCanWrite" title="Create copy of this product" class="a-btn"
                                    style="margin-right: 10px;"
                                    (click)="oOnCopyClicked.emit(row);$event.stopPropagation();">
                                    <i-feather name="copy"></i-feather>
                                </a>
                                <button *ngIf="showCustomAction(row)" type="button" class="btn btn-outline-dark button"
                                    style="padding-top: 3px;padding-bottom: 3px;"
                                    (click)="executeCustomAction(row)">{{iCustomActionLabel}}</button>
                            </td>
                            <td *ngIf="!(( iShowActions || iShowViewAction )  && (iCanWrite || iCanDelete || iCanRead || true))" class="hide-from-small"></td>
                        </tr>
                    </tbody>
                </table>
                <form [formGroup]="dtForm" *ngIf="iShowHeader">
                    <div class="d-flex justify-content-between align-items-center" style="justify-content: flex-end !important;">
                        <select style="border-radius: 16px; margin-right: 32px; max-width: 109px;" name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                            formControlName="pageSize" (change)="onPageSizeChange($event)" [(ngModel)]="iPageSize"
                            class="form-control form-select select-custom">
                            <option [value]="10">10 Per Page</option>
                            <option [value]="25">25 Per Page</option>
                            <option [value]="50">50 Per Page</option>
                            <option [value]="100">100 Per Page</option>
                            <option [value]="250">250 Per Page</option>
                            <option [value]="500">500 Per Page</option>
                        </select>
                        <div class="text-md-right float-md-end pagination-rounded">
                            <ngb-pagination [maxSize]="1" [rotate]="true" [ellipses]="true"
                                [collectionSize]="collectionSize" [page]="pageNumber" [boundaryLinks]="true"
                                (pageChange)="onPageChange($event)" [pageSize]="f.pageSize.value">
                            </ngb-pagination>
                        </div>
                    </div>
                </form>
            </div>
        </div> <!-- end col -->
    </div> <!-- end row -->
</div>