import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CAMPAIGN_STAGES, PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { createGuid, getRolesForPage } from '../../../core/helpers/utility';
import { IUser } from '../../../core/models/auth.models';
import { ICampaign, ICampaignAddAccount } from '../../../core/models/campaigns.model';
import { IClient } from '../../../core/models/clients.models';
import { ISalesRep } from '../../../core/models/datafeed.models';
import { DataFeedService } from '../../../shared/api/datafeed.service';
import { AppService } from '../../../store/app.service';
import { ClientsApiService } from '../../clients/clients.apiservice';
import { CampaignsApiService } from '../campaigns.apiservice';
@Component({
  selector: 'app-edit-campaign',
  templateUrl: './edit-campaign.component.html',
  styleUrls: ['./edit-campaign.component.scss']
})
export class EditCampaignComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public CAMPAIGN_STAGES = CAMPAIGN_STAGES;
  public allPlatforms = ['Campaign Consulting', 'Facebook', 'Google', 'TikTok', 'National Add On', 'Other', 'Website', 'YouTube'];
  public allBundles = ['Bundle1', 'Bundle2', 'Bundle3', 'Bundle4', 'Bundle5',];
  public defaultCRMNames = [
    { title: 'ClubReady', value: 'clubready' },
    { title: 'MindBody', value: 'mindbody' },
    { title: 'Loyalsnap', value: 'loyalsnap' },
    { title: 'Glofox', value: 'glofox' },
    { title: 'Other', value: 'Other' },
  ];
  isCustomCrmName = false;
  editCampaignForm!: FormGroup;
  submitted = false;

  allDms: Array<ISalesRep> = new Array<ISalesRep>();
  allClients: Array<IClient> = new Array<IClient>();
  allEmployees: Array<IUser> = new Array<IUser>();
  activeBundle: string = '';

  isAdmin: boolean = false;
  isExternal: boolean = false;
  canDelete: boolean = false;
  canRead: boolean = false;
  canWrite: boolean = false;
  isAddAcountMode: boolean = false;
  isUpdateAccountMode: boolean = false;
  currentAccountId: string = '';
  currentCampaignId: string = '';
  currentAdLabel: string = '';
  currentAdAccountId: string = '';
  isAddAccountSubmitted: boolean = false;
  options: any = null;
  campaignAdAccounts: Array<ICampaignAddAccount> = new Array<ICampaignAddAccount>();

  //new
  returnPath: string = '/pages/campaigns';

  constructor(
    private dataFeedService: DataFeedService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private campaignsService: CampaignsApiService,
    private clientService: ClientsApiService,
    private appService: AppService) {

    this.editCampaignForm = this.formBuilder.group({
      id: [null],
      campaignId: ['', [Validators.required]],
      subscriptionId: [''],
      subscriptionActive: [false],
      name: ['', [Validators.required]],
      platform: [''],
      dmsId: ['', [Validators.required]],
      stageId: [null, [Validators.required]],
      clientId: ['', [Validators.required]],
      opportunityNotes: [''],
      crmId: [''],
      crmName: [''],
      employees: [null],
      locationAddress: ['', [Validators.maxLength(200)]],
      locationPhone: ['', [Validators.maxLength(20)]],
      locationEmail: ['', [Validators.email, Validators.maxLength(50)]],
      //nextActionNotes: [''], https://atlastechdev.atlassian.net/browse/REAC-59
      //nextActionDate: [new Date()], https://atlastechdev.atlassian.net/browse/REAC-59
      //nextActionTime: ['00:00'], https://atlastechdev.atlassian.net/browse/REAC-59
      bundle: [''],
      platformCampaignId: [''],
      platformAccountId: [''],
    });
  }

  ngOnInit(): void {

    this.f.crmName.valueChanges
      .pipe(takeUntil(this.destroyed$)
      ).subscribe(value => {
        if (value === "Other") {
          this.f.crmName.setValue('');
          this.isCustomCrmName = true;
        }
      })


    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        this.isExternal = u.type == ROLES_TYPE.External;
        var r = getRolesForPage(u.roles, PAGE_NAMES.Campaigns);
        this.isAdmin = r.isAdmin;
        this.canRead = r.canRead;
        this.canWrite = r.canWrite;
        this.canDelete = r.canDelete;
        if (r.options)
          this.options = JSON.parse(r.options!)
      })
    let id = this.route.snapshot.paramMap.get('id');
    this.appService.setPageTitle$(`Campaign - ${id ? 'Edit' : 'Add'}`);
    if (id) {
      this.getCampaignDetails(id);
    }
    else {
      this.getNewCampaignId();
    }
    if (id) {
      this.returnPath = '/pages/campaign-details/' + id;
    }
    this.getAllSalesReps();
    this.getAllClients();
  }

  getAllSalesReps() {
    this.dataFeedService.getAllUsersWithRole(ROLES.DMS.Code)
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allDms = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });
  }

  getAllClients() {
    this.dataFeedService.getAllClients()
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allClients = [...response.data];
          // this.addClientForm.patchValue({ brandId: response.data[0] });
        }
      });
  }

  getCampaignDetails(id: any) {
    this.campaignsService.getCampaignDetails(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        // let date: Date = new Date(response.data.nextActionDate);
        // let time = `${date.getHours()}${":"}${date.getMinutes()}`;
        this.editCampaignForm.patchValue(response.data);
        this.campaignAdAccounts = response.data.campaignAdAccounts;
        this.editCampaignForm.patchValue(
          {
            clientId: response.data.client.id,
            employees: response.data.employees.map((x: any) => ({ id: x.id, fullName: `${x.firstName} ${x.lastName}` }))
          });
        this.isCustomCrmName = !(this.defaultCRMNames.find(x => x.value === response.data.crmName));
      });
  }

  getNewCampaignId() {
    let guid = createGuid();
    this.f.id.setValue(guid);

    this.campaignsService.getNewCampaignId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.data) this.f.campaignId.setValue(response.data);
      });
  }

  get f() { return this.editCampaignForm.controls; }

  editRow(evt: any) { }

  deleteRows(evt: any) { }

  onClientRowClick(evt: any) {
    this.router.navigate(['/pages/client-details', evt.id]);
  }

  updateProspectClientDetails() {
    let clientId = this.f.clientId.value;
    this.f.dmsId.setValue('');
    if (clientId) {
      this.clientService.getClientDetails(clientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          if (response.data.dmsId)
            this.f.dmsId.setValue(response.data.dmsId);
        });
    }
  }
  onShowDropdown() {
    this.isCustomCrmName = false;
    this.editCampaignForm.patchValue({ crmName: this.defaultCRMNames[0].value })
  }

  onSubmit() {
    this.submitted = true;
    if (this.editCampaignForm.invalid)
      return;
    if (this.f.id.value === null)
      this.f.id.setValue(createGuid())

    let formValues: ICampaign = this.editCampaignForm.value;
    formValues.campaignAdAccounts = this.campaignAdAccounts;

    // let actionTime = this.f.nextActionTime.value;
    // if (actionTime && actionTime != "") {
    //   let h = actionTime.split(":")[0];
    //   let m = actionTime.split(":")[1];
    //   let d = new Date(formValues.nextActionDate);
    //   d.setHours(h, m, 0);
    //   (formValues.nextActionDate as Date) = d;
    // }

    this.campaignsService.saveCampaign(formValues)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        //this.appService.fetchAllClients();
        this.router.navigate(['/pages/campaign-details/', formValues.id]);
      });


  }

  deleteAdAccount(adAccount: any){
    const index = this.campaignAdAccounts.findIndex((Ad: { id: string; }) => Ad.id == adAccount.id);
    this.campaignAdAccounts.splice(index, 1);
  }

  OnAddAccontClick(){
    this.isAddAcountMode = true;
    this.isUpdateAccountMode = false;
    this.currentAccountId = '';
    this.currentCampaignId = '';
    this.currentAdLabel = '';
    this.currentAdAccountId = '';
    this.isAddAccountSubmitted = false;
  }

  onEditAccountClick(adAccountId: string){
    var adAccount = this.campaignAdAccounts.find((Ad => Ad.id == adAccountId))
    if(adAccount){
      this.isAddAcountMode = true;
      this.isUpdateAccountMode = true;
      this.currentAccountId = adAccount.platformAccountID;
      this.currentCampaignId = adAccount.platformCampaignID;
      this.currentAdLabel = adAccount.adLabel;
      this.currentAdAccountId = adAccount.id;
      this.isAddAccountSubmitted = false;
    }
  }

  CancelAddMode(){
    this.isAddAcountMode = false;
    this.isUpdateAccountMode = false;
  }

  updateAccountDetail(){
    var adAccount = this.campaignAdAccounts.find((Ad => Ad.id == this.currentAdAccountId))
    if(adAccount){
      adAccount.adLabel = this.currentAdLabel;
      adAccount.platformAccountID = this.currentAccountId;
      adAccount.platformCampaignID = this.currentCampaignId;
    }
    this.isAddAcountMode = false;
    this.isUpdateAccountMode = true;
  }

  addAccountDetail(){
    var adObj = {
      id:createGuid(),
      rmCampaignId:this.f.id.value,
      platformCampaignID: this.currentCampaignId,
      platformAccountID: this.currentAccountId,
      adLabel: this.currentAdLabel
    }

    this.campaignAdAccounts.push(adObj);
    this.isAddAcountMode = false;
    this.isUpdateAccountMode = true;
  }

  ngAfterViewInit() {
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
