<div class="modal-box" cdkDrag>
    <form [formGroup]="productsForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between;" cdkDragHandle>
            <h4 class="modal-title">{{ id === '' ? 'Add' :'Edit'}} Product</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div class="col-12 col-lg-6 form-group mb-4">
                    <label for="name">Product Name</label>
                    <input type="text" class="form-control" formControlName="name"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Enter product name">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">product name is required</div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 form-group mb-4">
                    <label for="familyCode">Product Family</label>
                    <!-- <div class="col-sm-4"> -->

                    <ng-select (change)="onFamilyChange($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.familyCode.errors}" placeholder="Select family"
                        [items]="allProductFamilies" bindLabel="displayName" bindValue="code" formControlName="familyCode">
                    </ng-select>
                    <!-- </div> -->
                </div>
                <div *ngIf="f.familyCode.value === PRODUCT_FAMILY.AdSpend || f.familyCode.value === PRODUCT_FAMILY.DigitalMarketing || f.familyCode.value === PRODUCT_FAMILY.AddPlatform"
                    class="col-12 col-lg-9 form-group mb-4">
                    <div class="row">
                        <label><strong>
                                Select Marketing Platforms <span
                                    [ngClass]="{ 'custom-error':submitted && !(productsForm.errors?.atleastOneTrue)}">(you
                                    must
                                    select atleast one)</span>
                            </strong></label>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-sm-2">
                                    <input type="checkbox" id="isGoogle" (change)="onPlatformChange()"
                                        formControlName="isGoogle" class="form-check-input" />
                                </div>
                                <label class="col-sm-10" for="isGoogle">Google</label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-sm-2">
                                    <input type="checkbox" id="isFacebook" (change)="onPlatformChange()"
                                        formControlName="isFacebook" class="form-check-input" />
                                </div>
                                <label class="col-sm-10" for="isFacebook">Meta (Facebook/Instagram)</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-sm-2">
                                    <input type="checkbox" id="isTikTok" (change)="onPlatformChange()"
                                        formControlName="isTikTok" class="form-check-input" />
                                </div>
                                <label class="col-sm-10" for="isTikTok">TikTok</label>
                            </div>
                        </div>
                        <div *ngIf="f.familyCode.value !== PRODUCT_FAMILY.AddPlatform" class="col-md-6">
                            <div class="row">
                                <div class="col-sm-2">
                                    <input type="checkbox" (change)="onCustomerChoosePlatformChange()" id="canCustomerChoose"
                                        formControlName="canCustomerChoose" class="form-check-input" />
                                </div>
                                <label class="col-sm-10" for="canCustomerChoose">Customer can choose platforms</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6 form-group mb-4">
                    <label for="display">Display Name</label>
                    <input type="text" class="form-control " formControlName="display"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" placeholder="Enter display name">
                    <div *ngIf="submitted && f.display.errors" class="invalid-feedback">
                        <div *ngIf="f.display.errors.required">display name is required</div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 form-group mb-4">
                    <label for="description">Product Description</label>
                    <textarea type="text" rows="3" class="form-control " formControlName="description"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                        placeholder="Enter description"> </textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">description is required</div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 form-group mb-4">
                    <div class="row">
                        <div class="col-sm-1">
                            <input type="checkbox" id="isDefault" (change)="resetBundleProduct()" formControlName="isDefault" class="form-check-input" />
                        </div>
                        <label class="col-sm-5" for="isDefault">Default Product</label>

                        <div *ngIf="f.familyCode.value !== PRODUCT_FAMILY.Bundle" class="col-sm-6">
                            <div class="row">
                                <div class="col-sm-2">
                                    <!-- <input [attr.disabled]="f.familyCode.value === PRODUCT_FAMILY.AdSpend ? true :null" -->
                                    <input type="checkbox" id="isBundleOnly" formControlName="isBundleOnly"
                                        class="form-check-input" />
                                </div>
                                <label class="col-sm-7" for="isBundleOnly">Bundle Only</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-9 form-group mb-4">
                    <div class="row">
                        <label for="isDefault">Upload Media</label>
                        <div class="col-sm-12" fxLayout="row" fxLayoutAlign="start center">
                            <div class="card" style="padding: 15px; margin:0" fxLayout="row" fxLayoutAlign="start start">
                                <input style="display: none;" (change)="getFile($event)" type="file" id="selectedMedia"
                                    #file>
                                <button type="button" style="width: 120px; margin-top:5px"
                                    class="btn btn-page btn-add-media"
                                    onclick="document.getElementById('selectedMedia').click();">Choose file
                                </button>
                                <div style="margin:0px 5px 5px 20px">
                                    <div *ngIf="fProductMedia.fileName.value !==null">
                                        <img [src]="fProductMedia.file.value"
                                            [alt]="fProductMedia.fileName.value ?? 'No media found'" class="ms-lg-auto"
                                            style="height: 150px; object-fit: fill; border:1px solid #f1f0f0; padding: 5px 5px 5px 5px;" />
                                    </div>
                                    <div *ngIf="submitted && fProductMedia.fileName.errors" class="invalid-feedback">
                                        <div *ngIf="fProductMedia.fileName.errors.required">Please select an image for
                                            product
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-12 col-lg-3 mb-4" formGroupName="media">
                    <div class="row">
                        <div class="col-6 col-sm-3 col-lg-12 mb-3 text-lg-end">
                            <input style="display: none;" (change)="getFile($event)" type="file" id="selectedMedia" #file>
                            <button type="button" class="btn btn-sm btn-add-media"
                                onclick="document.getElementById('selectedMedia').click();">Upload Media &nbsp;
                                <i class="far fa-image fw-bold"></i>
                            </button>
                        </div>
                        <div class="col-6 col-lg-12 mb-3">

                        </div>
                    </div>
                </div> -->
                <div *ngIf="f.isDefault.value == false" class="col-12 form-group mb-4">
                    <label for="companyId">Company (Product Owner)</label>

                    <ng-select formControlName="companyId" [items]="allCompaniesFeed" (change)="resetBundleProduct()" bindValue="id" bindLabel="name">
                    </ng-select>

                    <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                        <div *ngIf="f.companyId.errors.required">company is required</div>
                    </div>
                </div>
                <div *ngIf="f.familyCode.value !== PRODUCT_FAMILY.Bundle" class="col-12 mb-3 mee-0">
                    <div class="row">
                        <label style="margin-top:6px" class="col-sm-3" for="productdata">Select Variation</label>
                        <div class="col-sm-9">
                            <div class="row">
                                <div class="col-12 mb-2 ps-0">
                                    <ng-select (change)="onTypeChange($event)" [ngClass]="{ 'is-invalid': submitted && f.type.errors }"
                                        placeholder="Select Subscription" formControlName="type">
                                        <ng-option *ngFor="let item of PRODUCT_TYPES | keyvalue" [value]="item.value.code">
                                            {{item.value.title}}</ng-option>
                                    </ng-select>
                                </div>

                                <ng-container formGroupName="data">
                                    <div class="col-sm-12 mb-3 border pt-2 pb-2"
                                        *ngIf="f.type.value === PRODUCT_TYPES.SimpleProduct.code">
                                        <div class="row">
                                            <label for="price" class="col-sm-2 col-form-label">
                                                Price ($)</label>
                                            <div class="col-sm-10">
                                                <input [ngClass]="{ 'is-invalid': submitted && fProductData.price.errors}"
                                                    type="number" class="form-control" formControlName="price" />
                                                <div *ngIf="submitted && fProductData.price.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="fProductData.price.errors.required">Price is required</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 mb-3 border pt-2 pb-2"
                                        *ngIf="f.type.value === PRODUCT_TYPES.SimpleSubscription.code || f.type.value === PRODUCT_TYPES.VariableSubscription.code">

                                        <div class="row mb-1"
                                            *ngIf="f.type.value === PRODUCT_TYPES.SimpleSubscription.code">
                                            <label for="price" class="col-sm-4 col-form-label">
                                                Price ($)</label>
                                            <div class="col-sm-7">
                                                <div class="input-group input-group">
                                                    <span class="input-group-text border-0 bg-none">$ </span>
                                                    <input
                                                        [ngClass]="{ 'is-invalid': submitted && fProductData.price.errors}"
                                                        type="text" class="form-control" aria-label="price"
                                                        formControlName="price">
                                                    <div *ngIf="submitted && fProductData.price.errors"
                                                        class="invalid-feedback">
                                                        <div *ngIf="fProductData.price.errors.required">Price is required
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-1">
                                            <label for="frequency" class="col-sm-4 center-label">
                                                Frequency</label>
                                            <!-- <div class="col-sm-2">Every</div> -->
                                            <div class="col-sm-4" style="display: flex;align-items: center;">Every
                                                <ng-select [clearable]="false" formControlName="frequency"
                                                    style="width: 100%; padding-left: 4px;"
                                                    [ngClass]="{ 'is-invalid': submitted && fProductData.frequency.errors }"
                                                    bindLabel="title" bindValue="value" [items]="frequencyOptions"
                                                    [multiple]="false">
                                                </ng-select>
                                                <div *ngIf="submitted && fProductData.frequency.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="fProductData.frequency.errors.required">Frequency is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <ng-select formControlName="duration" [items]="durationOptions"
                                                    bindLabel="title" bindValue="value"
                                                    [ngClass]="{ 'is-invalid': submitted && fProductData.duration.errors }"
                                                    [multiple]="false">
                                                </ng-select>
                                                <div *ngIf="submitted && fProductData.duration.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="fProductData.duration.errors.required">Duration is
                                                        required
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mb-1">
                                            <label for="expiresAfterCycles" class="col-sm-4 center-label">
                                                Expires After
                                            </label>
                                            <div class="col-sm-4">
                                                <ng-select [clearable]="false" formControlName="expiresAfterCycles"
                                                    [items]="expiresAfterOptions" [multiple]="false">
                                                </ng-select>
                                            </div>
                                            <div fxLayout="row" fxLayoutAlign="start center" class="col-sm-2">
                                                <p style="padding-left:0; margin: 0;" fxLayout="row"
                                                    fxLayoutAlign="start center">
                                                    Cycle(s)
                                                </p>
                                            </div>

                                        </div>

                                        <div class="row mb-1">
                                            <label for="signupFee" class="col-sm-4 center-label">
                                                Sign-Up Fee
                                            </label>
                                            <div class="col-sm-8">
                                                <div class="input-group input-group">
                                                    <span class="input-group-text border-0 bg-none">$ </span>
                                                    <input type="text" aria-label="signupFee" class="form-control"
                                                        formControlName="signupFee">
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="fProductData.duration.value !== 'day'" class="row">
                                            <label for="synchronizeRenewls" class="col-sm-4 center-label">Synchronize
                                                Renewals</label>
                                            <div class="col-sm-8">
                                                <ng-select formControlName="synchronizeRenewls"
                                                    [items]="synchronizeRenewalsOptions" bindLabel="title" bindValue="value"
                                                    [multiple]="false">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-container formArrayName="variableSubscription">

                                    <div class="col-12 mb-3 border pt-2 pb-2"
                                        *ngIf="f.type.value === PRODUCT_TYPES.VariableSubscription.code">

                                        <div class="row">
                                            <label for="addAmount" class="col-3 col-form-label center-label">Amount
                                                ($)</label>
                                            <div class="col-sm-9">
                                                <div class="row">
                                                    <div class="col-12 col-sm-4 col-md-2 mb-3">
                                                        <button style="margin-left:-10px" type="button"
                                                            (click)="addVariableSubscription()"
                                                            class="btn btn-sm btn-dark px-4 px-lg-5">Add</button>
                                                    </div>
                                                    <div class="col-12 border py-2 variable-subscriptions-list mb-2 variable-subscriptions-boundary"
                                                        cdkDropList
                                                        (cdkDropListDropped)="rmProductsVariableSubscriptionsDragNDrop($event)">
                                                        <div class="row"
                                                            *ngFor="let vs of fProductVariableSubscription.controls; let i=index">
                                                            <div class="col-auto variable-subscriptions-box" cdkDrag
                                                                cdkDragBoundary=".variable-subscriptions-boundary"
                                                                cdkDragLockAxis="y" [formGroupName]="i">
                                                                <div class="input-group input-group mb-1">
                                                                    <span class="input-group-text">$ </span>
                                                                    <input type="text" class="form-control"
                                                                        aria-label="Amount" formControlName="amount">
                                                                    <span class="input-group-text"
                                                                        title="Drag up or down to change order.">
                                                                        <i class="fas fa-arrows-alt"></i>
                                                                    </span>
                                                                    <span class="input-group-text bg-white"
                                                                        title="Drag up or down to change order.">
                                                                        <i class="fas fa-sort">
                                                                            <small>
                                                                                {{
                                                                                fProductVariableSubscription.controls[i].value.sortOrder
                                                                                }}
                                                                            </small>
                                                                        </i>
                                                                    </span>
                                                                    <span class="input-group-text" style="cursor: pointer;"
                                                                        title="Click to remove amount from list."
                                                                        (click)="deleteVariableSubscription(i)">
                                                                        <i class="fas fa-times"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>


                <ng-container *ngIf="f.familyCode.value === PRODUCT_FAMILY.Bundle" formArrayName="bundleProducts">
                    <div class="row pt-2 pb-2">
                        <div class="col-4">
                            <button type="button" class="btn btn-sm btn-dark mt-2" (click)="addBundleProduct()">
                                Add Product</button>
                        </div>
                    </div>

                    <div class="row mb-3 pt-2 pb-2">
                        <div class="col-12" *ngFor="let pb of fProductBundle.controls; let i=index">
                            <div [formGroupName]="i" class="row">
                                <input type="hidden" formControlName="sortOrder" />
                                <div class="col-sm-5">
                                    <ng-select
                                        [ngClass]="{ 'is-invalid': submitted && fProductBundle.at(i).get('assignedProductId')?.errors}"
                                        placeholder="" [items]="allProductsFiltered[i]" bindLabel="name" bindValue="id"
                                        formControlName="assignedProductId">
                                    </ng-select>
                                </div>
                                <div class="col-sm-2">
                                    <input class="form-check-input mt-0 me-2" type="checkbox" formControlName="isRequired"
                                        aria-label="Checkbox for following text input">
                                    required?
                                </div>
                                <div class="col-sm-1">
                                    <span class="input-group-text" style="cursor: pointer;"
                                        title="Click to remove from list." (click)="deleteBundleProduct(i)">
                                        <i class="fas fa-times"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>
        </div>
        <div class="modal-footer" style="cursor: move; display: flex; flex-direction: row; justify-content: space-between; align-items: center; align-content: center; flex-wrap: wrap;" cdkDragHandle>
            <button type="button" style="border: 1px solid red; border-radius: 4px; padding: 8px; color: red;" (click)="activeModal.close('Close click')">Cancel</button>
            <button type="submit" class="blue-button">Save</button>
        </div>
    </form>
</div>