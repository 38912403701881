<header id="page-topbar">
    <div class="d-flex flex-row flex-space-between">
        <div class="d-flex">
            <div class="d-flex" style="align-items: center;">
                    <button type="button" style="padding: 15px 20px; min-width: 69px;"
                        class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
                        (click)="toggleMobileMenu($event)">
                        <i class="fa fa-fw fa-bars"></i>
                    </button>
                <span *ngIf="toggleLogo" class="page-title hide-from-980" style="min-width: 150px; margin-right: 16px;">
                    <h1>REACH</h1>
                </span>
                <div [ngClass]="(activePage !== PAGE_NAMES.Subscriptions) ? 'right-element' : 'right-element hide-from-980'">
                    <span class="page-title">
                        <h1>{{ (appService.getPageTitle$() | async) | uppercase}}</h1>
                    </span>
                </div>
            </div>
        </div>
        <div class="d-flex dv-date align-items-center right-element">
        </div>
        <div class="d-flex flex-row top-header-right hide-from-768" style="align-items: center !important;">
            <div style="display: flex; flex-direction: row; align-items: center; padding: 8px 4px 8px 8px; border: 1px solid white; border-radius: 16px; min-width: 265px; background-color: white; max-height: 34px !important;">
                <i-feather name="search" style="width: 16px; height: 16px; color: grey;"></i-feather>
                <input type="text" placeholder="Search" class="form-control form-control-sm ms-2"
                    [(ngModel)]="searchKeyword" style="border: none !important; background-color: white !important; max-height: 15px; max-width: 220px;"
                    (keyup.enter)="search()">
            </div>
            <div id="templates" class="d-flex">
                <ng-template [ngIf]="activePage===PAGE_NAMES.Dashboard">
                    <div class="d-flex align-items-center">
                        <button type="button" class="blue-button" style="margin-right: 15px;"
                            (click)="addWidgetClick()">Add
                            Widget</button>
                        <button type="button" class="blue-button"
                            (click)="changeLayoutClick()">{{changeLayoutText()}}</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Clients && (isAdmin || permissions[PAGE_NAMES.Clients]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" (click)="onEditClick('add')">ADD CLIENT</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.ClientDetails && (isAdmin || permissions[PAGE_NAMES.Clients]?.canWrite || permissions[PAGE_NAMES.Clients]?.canDelete)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Clients]?.canWrite)" type="button"
                            class="blue-button" (click)="onEditClick('edit')">EDIT
                            CLIENT</button>
                        <button style="margin-left: 5px;"
                            *ngIf="(isAdmin || permissions[PAGE_NAMES.Clients]?.canDelete)" type="button"
                            class="blue-button" (click)="onDeleteClientClick()">DELETE
                        </button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Prospects && (isAdmin || permissions[PAGE_NAMES.Prospects]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" (click)="onEditClick('add')">ADD
                            PROSPECT</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="(activePage===PAGE_NAMES.ProspectsDetails) && (isAdmin || permissions[PAGE_NAMES.Prospects]?.canWrite || permissions[PAGE_NAMES.Prospects]?.canDelete)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Prospects]?.canWrite)" 
                            type="button" class="blue-button" (click)="onEditClick('edit')">EDIT
                            PROSPECT</button>
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Prospects]?.canDelete)"
                            style="margin-left: 5px;" type="button" class="blue-button"
                            (click)="onDeleteProspectClick()">DELETE
                        </button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Campaigns && (isAdmin || permissions[PAGE_NAMES.Campaigns]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" [routerLink]="['edit-campaign/']" class="blue-button">ADD A CAMPAIGN</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Coupons && (isAdmin || permissions[PAGE_NAMES.Coupons]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" [routerLink]="['edit-coupon/']" class="blue-button">ADD COUPON</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.CampaignsDetails && (isAdmin || permissions[PAGE_NAMES.Campaigns]?.canWrite || permissions[PAGE_NAMES.Campaigns]?.canDelete)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Campaigns]?.canWrite)" type="button"
                            [routerLink]="['edit-campaign/' +(appService.getActiveRoute$() | async).url.split('/').slice(-1)[0]]"
                            class="blue-button">EDIT CAMPAIGN</button>
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Campaigns]?.canDelete)"
                            style="margin-left: 5px;" type="button" class="blue-button"
                            (click)="onDeleteCampaignClick()">DELETE
                        </button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.Tags && (isAdmin || permissions[PAGE_NAMES.Tags]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" [routerLink]="['edit-tag/']">ADD TAG</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.EditTag">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" [routerLink]="['tags/']">BACK TO LIST</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.EditCampaign">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" [routerLink]="['campaigns/']">BACK TO LIST</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.GlobalSearch">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.Searches">
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Users && (isAdmin || permissions[PAGE_NAMES.Users]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" (click)="onUserClick('add')">ADD USER</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Companies && (isAdmin || permissions[PAGE_NAMES.Companies]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button" (click)="onCompanyClick('add')">ADD COMPANY</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="((activePage===PAGE_NAMES.Leads || activePage===PAGE_NAMES.LeadDetails) && ((isAdmin) || permissions[PAGE_NAMES.Leads]?.canCreate || permissions[PAGE_NAMES.Leads]?.canDelete))">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Leads]?.canCreate)"
                            type="button" class="blue-button" (click)="onLeadsClick()">ADD LEAD</button>
                        <button *ngIf="activePage===PAGE_NAMES.LeadDetails && ((isAdmin) || permissions[PAGE_NAMES.Leads]?.canDelete)"
                            style="margin-left: 5px;" type="button" class="blue-button"
                            (click)="onLeadDeleteClick()">DELETE</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.Products && (isAdmin || permissions[PAGE_NAMES.Products]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" class="blue-button"
                            (click)="onProductsAddClick('add')">ADD PRODUCT</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.ProductsDetails && (isAdmin || permissions[PAGE_NAMES.Products]?.canWrite || permissions[PAGE_NAMES.Products]?.canDelete)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button *ngIf="(isAdmin || permissions[PAGE_NAMES.Products]?.canWrite)" type="button"
                            class="blue-button" (click)="onProductsEditClick('edit')">EDIT PRODUCT</button>    
                        <button style="margin-left: 5px;"
                            *ngIf="(isAdmin || permissions[PAGE_NAMES.Products]?.canDelete)" type="button"
                            class="blue-button" (click)="onProductsDeleteClick()">DELETE</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.Subscriptions && (isAdmin || permissions[PAGE_NAMES.Subscriptions]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" (click)="onSubscriptionAddClick()" class="blue-button">ADD SUBSCRIPTION</button>
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.SubscriptionsDetails && (isAdmin || permissions[PAGE_NAMES.Subscriptions]?.canWrite)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" (click)="onSubscriptionEditClick()"
                            class="blue-button">EDIT SUBSCRIPTION</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.Orders && (isAdmin || permissions[PAGE_NAMES.Orders]?.canWrite)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" (click)="onOrderAddClick()" class="blue-button">ADD ORDER</button>
                    </div>
                </ng-template>
                <ng-template [ngIf]="activePage===PAGE_NAMES.Shop">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                    </div>
                </ng-template>
                <ng-template
                    [ngIf]="activePage===PAGE_NAMES.MyAccount && (isAdmin || permissions[PAGE_NAMES.MyAccount]?.canCreate)">
                    <div class="btn-block d-flex justify-content-end align-items-center" style="display: flex;">
                        <button type="button" (click)="onAddNewPaymentMethod()" class="blue-button">ADD PAYMENT METHOD</button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</header>