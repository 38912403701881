import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PRODUCT_FAMILY } from '../../../core/helpers/constants';
import { atleasOneTrue } from '../../../core/helpers/utility';
import { ICampaign } from '../../../core/models/campaigns.model';
import { IClient } from '../../../core/models/clients.models';
import { IProductMain } from '../../../core/models/products.models';
import { CampaignsApiService } from '../../campaigns/campaigns.apiservice';

@Component({
  selector: 'app-product-input',
  templateUrl: './product-input.component.html',
  styleUrls: ['./product-input.component.scss']
})

/**
 * Page Title Component
 */
export class ProductInputComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  PRODUCT_FAMILY = PRODUCT_FAMILY;
  @Input() set iAllMyClients(clients: Array<IClient>) {
    this.allMyClients = [...clients];
  }
  @Input() public iProductFamily: number = 1;
  @Input() iApGoogle: boolean = false;
  @Input() iApFacebook: boolean = false;
  @Input() iProduct: IProductMain = {} as IProductMain;
  @Input() set iSelectClient(clientId: string) {
    this.fDigiForm.clientId.setValue(clientId);
  }
  @Output() oOnSubmit: EventEmitter<any> = new EventEmitter();
  @Output() oOnChangeCampaignSelectMode: EventEmitter<any> = new EventEmitter();
  @Output() oOnClientSelected: EventEmitter<string> = new EventEmitter();
  @Output() oOnCampaignSelected: EventEmitter<string> = new EventEmitter();

  isNewCampaign: boolean = false;
  myCampaigns: Array<ICampaign> = new Array<ICampaign>();
  allMyClients = new Array<IClient>();


  formsMap = new Map<number, FormGroup>();
  formsSubmittedMap = new Map<number, boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private campaignApiService: CampaignsApiService
  ) {
    this.formsMap.set(PRODUCT_FAMILY.DigitalMarketing,
      this.formBuilder.group({
        campaignId: [null, [Validators.required]],
        clientId: [null, [Validators.required]],
        brandName: [null],
        studioLocationName: [null],
        campaignName: [null],
        studioEmail: [null],
        studioPhone: [null],
        studioAddress: [null],
        fbPageUrl: [null],
        crmName: [null],
        crmId: [null],
        notes: [null],
        isFacebook: [null],
        isGoogle: [null]
      }));
    this.formsMap.set(PRODUCT_FAMILY.AddPlatform,
      this.formBuilder.group({
        campaignId: [null, [Validators.required]],
        clientId: [null, [Validators.required]],
        isFacebook: [null],
        isGoogle: [null]
      }));
    this.formsSubmittedMap.set(PRODUCT_FAMILY.DigitalMarketing, false);

    this.formsMap.set(PRODUCT_FAMILY.CampaignConsulting,
      this.formBuilder.group({
        notes: [null, [Validators.required]],
      }));
    this.formsSubmittedMap.set(PRODUCT_FAMILY.CampaignConsulting, false);


    this.formsMap.set(PRODUCT_FAMILY.SEOProgram,
      this.formBuilder.group({
        businessAddress: [null, [Validators.required]],
        businessEmail: [null, [Validators.required, Validators.email]],
        businessPhone: [null, [Validators.required]],
        businessName: [null, [Validators.required]],
        businessWebsiteUrl: [null, [Validators.required]],
        notes: [null],
      }));
    this.formsSubmittedMap.set(PRODUCT_FAMILY.SEOProgram, false);


    this.formsMap.set(PRODUCT_FAMILY.HostedWebsite,
      this.formBuilder.group({
        websiteInfo: [null, [Validators.required]],
        notes: [null],
      }));
    this.formsSubmittedMap.set(PRODUCT_FAMILY.HostedWebsite, false);

    this.formsMap.set(PRODUCT_FAMILY.SocialMedia,
      this.formBuilder.group({
        websiteUrl: [null, [Validators.required]],
        instagramUrl: [null, [Validators.required]],
        facebookUrl: [null, [Validators.required]],
        notes: [null],
      }));
    this.formsSubmittedMap.set(PRODUCT_FAMILY.SocialMedia, false);
  }
  get fDigiForm() { return this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls!; }
  get fAddPlatformForm() { return this.formsMap.get(PRODUCT_FAMILY.AddPlatform)?.controls!; }
  get fCampaignConsultingForm() { return this.formsMap.get(PRODUCT_FAMILY.CampaignConsulting)?.controls!; }
  get fSEOForm() { return this.formsMap.get(PRODUCT_FAMILY.SEOProgram)?.controls!; }
  get fHostedWebsiteForm() { return this.formsMap.get(PRODUCT_FAMILY.HostedWebsite)?.controls!; }
  get fSocialMediaForm() { return this.formsMap.get(PRODUCT_FAMILY.SocialMedia)?.controls!; }

  onNameChange(evt: any) {
    let a = this.fDigiForm.brandName?.value || '';
    let b = this.fDigiForm.studioLocationName?.value || '';
    let c = (a.length || b.length) && this.iApFacebook ? 'FB' : ''
    this.fDigiForm.campaignName.setValue(a + ' ' + b + ' ' + c);
  }

  onSubmit(formKey?: number) {
    let key = this.iProductFamily;
    this.formsSubmittedMap.set(key, true);
    if (this.formsMap.get(key)?.invalid)
      return false;

    if (this.iApFacebook)
      this.formsMap?.get(PRODUCT_FAMILY.DigitalMarketing)?.patchValue({ isFacebook: true, isGoogle: false });
    else if (this.iApGoogle)
      this.formsMap?.get(PRODUCT_FAMILY.DigitalMarketing)?.patchValue({ isFacebook: false, isGoogle: true });
    return true;
  }

  getFormKey() {
    return this.iProductFamily;
  }
  getFormValues(formKey: number) {
    return this.formsMap.get(formKey)?.value;
  }
  setFormValues(value: any) {
    this.formsMap?.get(this.iProductFamily)?.patchValue(value);
  }
  ngOnInit(): void {
    this.fDigiForm.clientId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(v => {
        this.getCampaignsByClient(v);
      });
    this.fAddPlatformForm.clientId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(v => {
        this.getCampaignsByClient(v);
      });

    this.fDigiForm.campaignId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(v => {
        this.oOnCampaignSelected.emit(v);
      });
    this.fAddPlatformForm.campaignId.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(v => {
        this.oOnCampaignSelected.emit(v);
      });
  }

  getCampaignsByClient(clientId: any) {
    this.oOnClientSelected.emit(clientId);
    if (clientId) {
      this.formsMap?.get(this.iProductFamily)?.patchValue({ campaignId: null });
      this.campaignApiService.getCampaignsNamesByClient(clientId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((res: any) => {
          if (res.good)
            this.myCampaigns = res.data.map((x: any) => {
              x.disabled = x.rmSubscriptionId != null && x.rmSubscriptionId.length > 0;
              return x;
            });
        });
    }
  }

  onAddCampaign(newCampaign: boolean) {
    this.oOnChangeCampaignSelectMode.emit({ newCampaign });
    this.isNewCampaign = newCampaign;

    this.fDigiForm.campaignId.setValue(null);
    this.fDigiForm.brandName.setValue(null);
    this.fDigiForm.studioLocationName.setValue(null);
    this.fDigiForm.campaignName.setValue(null);
    this.fDigiForm.studioEmail.setValue(null);
    this.fDigiForm.studioPhone.setValue(null);
    this.fDigiForm.studioAddress.setValue(null);
    this.fDigiForm.fbPageUrl.setValue(null);
    this.fDigiForm.crmName.setValue(null);
    this.fDigiForm.crmId.setValue(null);
    this.fDigiForm.notes.setValue(null);
    this.fDigiForm.isFacebook.setValue(null);
    this.fDigiForm.isGoogle.setValue(null);

    this.fDigiForm.campaignId.setValidators(newCampaign ? [] : [Validators.required]);
    var validators = newCampaign ? [Validators.required] : [];
    this.fDigiForm.brandName.setValidators(validators);
    this.fDigiForm.studioLocationName.setValidators(validators);
    this.fDigiForm.studioEmail.setValidators(validators);
    this.fDigiForm.studioPhone.setValidators(validators);
    this.fDigiForm.studioAddress.setValidators(validators);
    // this.fDigiForm.crmName.setValidators(validators);
    // this.fDigiForm.crmId.setValidators(validators);
    if (newCampaign && this.iProduct.canCustomerChoose)
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.setValidators([atleasOneTrue]);
    else
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.setValidators([]);

    this.fDigiForm.campaignId.updateValueAndValidity();
    this.fDigiForm.brandName.updateValueAndValidity();
    this.fDigiForm.studioLocationName.updateValueAndValidity();
    this.fDigiForm.studioEmail.updateValueAndValidity();
    this.fDigiForm.studioPhone.updateValueAndValidity();
    this.fDigiForm.studioAddress.updateValueAndValidity();
    this.fDigiForm.crmName.updateValueAndValidity();
    this.fDigiForm.crmId.updateValueAndValidity();
    this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCopyNewCampaign(copydata: any) {
    //this.onAddNewCampaign();
    if (copydata.campaignId) {
      let campaign = this.myCampaigns.filter(x => x.id === copydata.campaignId)[0];
      let campaignNameArr = campaign.name.split(' ');
      campaignNameArr = campaignNameArr.filter(x => x !== 'FB');
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.brandName.setValue(campaignNameArr[0]);
      if (campaignNameArr.length > 1)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioLocationName.setValue(campaignNameArr.slice(1).join(' '));
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.campaignName.setValue(campaignNameArr.join(' '));
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioEmail.setValue(campaign.locationEmail);
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioPhone.setValue(campaign.locationPhone);
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioAddress.setValue(campaign.locationAddress);
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.crmName.setValue(campaign.crmName);
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.crmId.setValue(campaign.crmId);
      this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.notes.setValue(campaign.opportunityNotes);
    } else {
      if (copydata.brandName)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.brandName.setValue(copydata.brandName);
      if (copydata.studioLocationName)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioLocationName.setValue(copydata.studioLocationName);
      if (copydata.brandName && copydata.studioLocationName)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.campaignName.setValue(copydata.brandName + " " + copydata.studioLocationName);
      if (copydata.studioEmail)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioEmail.setValue(copydata.studioEmail);
      if (copydata.studioPhone)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioPhone.setValue(copydata.studioPhone);
      if (copydata.studioAddress)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.studioAddress.setValue(copydata.studioAddress);
      if (copydata.crmName)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.crmName.setValue(copydata.crmName);
      if (copydata.crmId)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.crmId.setValue(copydata.crmId);
      if (copydata.notes)
        this.formsMap.get(PRODUCT_FAMILY.DigitalMarketing)?.controls?.notes.setValue(copydata.notes);
    }
  }
}
