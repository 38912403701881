import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_NAMES, ROLES, ROLES_TYPE } from '../../../core/helpers/constants';
import { getRolesForPage } from '../../../core/helpers/utility';
import { UtilityService } from '../../../core/helpers/utility.service';
import { IClient } from '../../../core/models/clients.models';
import { ITask } from '../../../core/models/tasks.models';
import { RefreshSignals } from '../../../layouts/sidebar/menu.model';
import { IColumn, LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { AppService } from '../../../store/app.service';
import { TasksApiService } from '../../tasks/tasks.apiservice';
import { TasksService } from '../../tasks/tasks.service';
import { ClientsApiService } from '../clients.apiservice';
import { ICampaign } from '../../../core/models/campaigns.model';
import { CampaignsApiService } from '../../campaigns/campaigns.apiservice';
import { IShopOrder } from '../../../core/models/shop.models';
import { IPermission } from '../../../core/models/auth.models';
@Component({
  selector: 'app-prospect-details',
  templateUrl: './prospect-details.component.html',
  styleUrls: ['./prospect-details.component.scss']
})
export class ProspectDetailsComponent implements OnInit, OnDestroy {

  active = 1;
  public ROLES = ROLES;
  columnsTasks: Array<IColumn> = [];
  compltedTasksColumns: Array<IColumn> = []
  tasksData: Array<ITask> = new Array<ITask>();
  completedTasksData: Array<ITask> = new Array<ITask>();
  permTasksData: Array<ITask> = new Array<ITask>();
  clientData: IClient

  roleOptions: any = {};
  isAdmin: boolean = false;
  public userRoles: Array<number> = [];
  isExternal: boolean = false;

  //new
  orderColumns = [
    { title: 'Order', field: 'orderName', sortable: true, searchable: true },
    { title: 'Date', field: 'orderDate', sortable: true, searchable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY HH:mm'] },
    { title: 'Status', field: 'status', sortable: true, searchable: true, pipe: 'P_OrderStatus' },
    { title: 'Total', field: 'total', sortable: true, searchable: true, pipe: 'Currency', pipeArgs: ['USD', 'symbol', '1.0-2'] },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientGuid'},
    { title: 'Subscription ID', field: 'subscriptionNumber', sortable: true, searchable: true, anchorLink: true, anchorType: 'subscription', anchorParamsField: 'subscriptionId' },
    // { title: 'Sales Rep', field: 'salesRep', sortable: true, searchable: true },
    // { title: 'DMS', field: 'dms', sortable: true, searchable: true },
  ]
  doWhat: IPermission = {} as IPermission;
  showTasks: boolean = true;
  showCompletedTasks: boolean = false;
  showOnlyMyTasks: boolean = true;
  userId: string = '';

  dataTotalCount: number = 0;
  private loadEvent!: LoadDataFromServer;
  ordersData: Array<IShopOrder>;
  campaignsData: Array<ICampaign> = [];
  columnsCampaign = [
    { title: 'Name', field: 'name', sortable: true, searchable: true },
    { title: 'Client', field: 'clientName', sortable: true, searchable: true },
    { title: 'Platform', field: 'platform', sortable: true, searchable: true },
    { title: 'DMS', field: 'dmsName', sortable: true, searchable: true },
    { title: 'Stage', field: 'stageId', sortable: true, pipe: 'P_CampaignStageTitle', searchable: true },
    { title: 'Active Subscription', field: 'subscriptionActive', pipe: 'P_Boolean', searchable: true },
  ];
  
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private route: ActivatedRoute,
    public uService: UtilityService,
    private router: Router,
    private taskApiService: TasksApiService,
    private tasksService: TasksService,
    private appService: AppService,
    private clientService: ClientsApiService,
    private campaignsService: CampaignsApiService,) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.clientData = {} as IClient;
    this.columnsTasks.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.columnsTasks.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.columnsTasks.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.columnsTasks.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
    this.columnsTasks.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code)) } as IColumn);


    this.compltedTasksColumns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.compltedTasksColumns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.compltedTasksColumns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.compltedTasksColumns.push({ title: 'Completed On', field: 'completedOn', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['YYYY/MM/DD HH:mm', true] } as IColumn);
    this.compltedTasksColumns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, visible: (this.userRoles.includes(ROLES.Admin.Code) || this.userRoles.includes(ROLES.TechServices.Code)) } as IColumn);

    //new
    this.campaignsData = new Array<ICampaign>();
    this.ordersData = new Array<any>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Prospect');
    this.appService.setRefreshSignal$(PAGE_NAMES.ProspectsDetails, '');

    this.appService.getActiveUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(u => {
        if (u.id !== undefined) {
          this.userId = u.id;
        }
        this.isExternal = u.type == ROLES_TYPE.External
        var r = getRolesForPage(u.roles, PAGE_NAMES.Clients);
        this.isAdmin = r.isAdmin;
        if (this.isAdmin) {
          this.showOnlyMyTasks = false;
        }
        if (r.options)
          this.roleOptions = JSON.parse(r.options!);
      })
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.clientService.getClientDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.clientData = response.data;
        });
      this.taskApiService.getTasksByClient(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.tasksData = [...response.data.filter((x: ITask) => (x.isCompleted === null || x.isCompleted === false) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
          this.permTasksData = [...response.data];
        });
    }
    const sub = this.appService.getRefreshSignal$(PAGE_NAMES.ProspectsDetails);
    if (sub !== null) {
      sub.pipe(takeUntil(this.destroyed$))
        .subscribe((r: RefreshSignals) => {
          this.doRefresh(r.id);
        })
    }
    this.campaignsService.getCampaignsByClient(id)
    .pipe(takeUntil(this.destroyed$))
    .subscribe((response: any) => {
      this.campaignsData = response.data
    });
    
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Orders);
    this.appService.getAllShopOrdersFiltered()
    .pipe(takeUntil(this.destroyed$)
    )
    .subscribe((response: any) => {
      //response.map(x => x.paymentAddress = (x.status === 3 ? x.paymentAddress : 'N/A'));
      this.ordersData = [...response.data].filter((x: IShopOrder) => x.clientGuid === id);
      this.dataTotalCount = this.ordersData.length;
    });
    this.configureTasksService();
  }

  doRefresh(id: string) {
    if (id !== "")
      this.clientService.getClientDetails(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.clientData = response.data;
        });
  }
  onTaskRowClick(evt: any) {
    this.tasksService.openEditTaskModal(evt, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  onCampaignsRowClick(evt: any) {
    this.router.navigate(['/pages/campaign-details', evt.id]);
  }

  loadTasksData() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.taskApiService.getTasksByClient(id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.tasksData = [...response.data.filter((x: ITask) => (x.isCompleted === null || x.isCompleted === false) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
          this.completedTasksData = [...response.data.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true))];
        });
    }
  }

  configureTasksService() {
    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadTasksData();
        }
      });
  }

  ngAfterViewInit() {
  }


  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onAddCampaign() {
    this.router.navigate(['pages/edit-campaign/']);
  }

  onAddTask() {
    this.tasksService.openEditTaskModal({ id: null, clientId: this.clientData.id }, this.isAdmin || this.roleOptions.tasks.canEdit, this.isAdmin || this.roleOptions.tasks.canDelete);
  }

  //new
  getCampaignRowLink(row: any) {
    return `/pages/campaign-details/${row.id}`;
  }
  
  //new2
  onClick(event: any) {
    this.router.navigate(['/pages/orders/details', event.id]);
  }

  onLoadingOrderData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.appService.fetchAllShopOrdersByFilter({
      // SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  //new3
  changeShowTasks() {
    if (this.showCompletedTasks) {
      this.showTasks = true;
      this.showCompletedTasks = false;
    }
  }
  
  changeShowCompletedTasks() {
    if (this.showTasks) {
      this.completedTasksData = this.permTasksData.filter((x: ITask) => (x.isCompleted && x.isCompleted === true) && (this.showOnlyMyTasks ? x.assignedUserId.includes(this.userId) : true));
      this.showTasks = false;
      this.showCompletedTasks = true;
    }
  }

  onShowMyTasksChange() {
    this.loadTasksData();
  }
}