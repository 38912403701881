import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { IAdsReportEmailModel, IReportingConfig, IReportingPeriod } from '../../core/models/reporting.models';
import { HttpService } from '../../shared/http/base.http';  
import { IRollupReportDataResponse, IRollupReportDataStatus, ICampaignDataStatus } from '../../core/models/reports.models';

@Injectable()
export class ReportsApiService extends HttpService {
   getReportingConfigsByClient(clientId: any) {
      return this.get<Array<IReportingConfig>>('/reportingConfig/getReportingConfigsByClient?clientId=' + clientId);
   }
   getSupportedReportingPeriods() {
      return this.get<Array<IReportingPeriod>>('/datafeed/getSupportedDatesOptions');
   }
   saveReportingConfig(data: IReportingConfig) {
      return this.post('/reportingConfig/saveReportingConfig', data, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   deleteReportingConfig(ids: Array<string>) {
      return this.post('/reportingConfig/deleteReportingConfig', ids, { 'Content-Type': 'application/json; charset=utf-8' });
   }
   getWeeklyReportsModels(): Observable<{ good: boolean, data: Array<any> }> {
      return this.get<{ good: boolean, data: Array<any> }>('/weeklyReports/weekly-reports-models');
   }
   sendSingleWeeklyReport(reports: { to: string, ccs?: string, subject?: string, reportModels: Array<IAdsReportEmailModel> }) {
      return this.post('/weeklyReports/send-custom', reports, { 'Content-Type': 'application/json; charset=utf-8' });
   }

   startRollupReportProcess(reportDataRequest: string): Observable<{ good: boolean, data: IRollupReportDataResponse }> {
      return this.get<{ good: boolean, data: IRollupReportDataResponse }>(`/dashboard/rollup-report${reportDataRequest}`);
   }
   getRollupReportData(OrderId: string): Observable<{ good: boolean, data: IRollupReportDataResponse }> {
      return this.get<{ good: boolean, data: IRollupReportDataResponse }>(`/dashboard/rollup-report-data?OrderId=${OrderId}`);
   }
   getRollupReportProgress(OrderId: string): Observable<{ good: boolean, data: IRollupReportDataStatus }> {
      return this.get<{ good: boolean, data: IRollupReportDataStatus }>(`/dashboard/rollup-report-status?OrderId=${OrderId}`);
   }
   getRollupReportCampaignsProgress(OrderId: string): Observable<{ good: boolean, data: Array<ICampaignDataStatus> }> {
      return this.get<{ good: boolean, data: Array<ICampaignDataStatus> }>(`/dashboard/rollup-report-campaigns-status?OrderId=${OrderId}`);
   }

   getRollupReport(orderId: string): Observable<any> {
      var uri = `/dashboard/completed-rollup-report?OrderId=${orderId}`;
      return new Observable((observer: Subscriber<any>) => {
         let reportUrl: string | null = null;

         this.getAsBlob(uri)
            .then(response => {
               reportUrl = URL.createObjectURL(response);
               observer.next(reportUrl);
            },
               err => {
                  console.error(err);
               }
            );

         return () => {
            if (reportUrl) {
               URL.revokeObjectURL(reportUrl);
               reportUrl = null;
            }
         };
      });
   }
}



