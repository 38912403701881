import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ILead } from '../../../core/models/lead.models';
import { AppService } from '../../../store/app.service';
import { LeadsApiService } from '../leads.apiservice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddLeadModal } from '../../../shared/modals/add-lead/add-lead.component';
import { IPermission } from '../../../core/models/auth.models';
import { PAGE_NAMES } from '../../../core/helpers/constants';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-leads',
  templateUrl: './leads-main.component.html',
  styleUrls: ['./leads-main.component.scss']
})
export class LeadsMainComponent implements OnInit {
  doWhat: IPermission = {} as IPermission;
  columns = [
    { title: 'Received At', field: 'createdAt', sortable: true, searchable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY HH:mm', true] },
    { title: 'First Name', field: 'firstName', sortable: true, searchable: true },
    { title: 'Last Name', field: 'lastName', sortable: true, searchable: true },
    { title: 'Email', field: 'email', sortable: true, searchable: true },
    { title: 'Phone', field: 'phone', sortable: true, searchable: true },
    { title: 'Campaign', field: 'campaignName', sortable: true, searchable: true },
    { title: 'Referal Source', field: 'referralSource', sortable: true, searchable: true },
  ]
  leadsData: Array<ILead>;
  leadsTotalCount: number = 0;
  savedSearchId!: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private loadEvent!: LoadDataFromServer;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private appService: AppService,
    private leadsApi: LeadsApiService,
    private spinner: NgxSpinnerService) {
    this.leadsData = new Array<ILead>();
  }

  ngOnInit(): void {
    this.appService.setPageTitle$('Leads');
    this.doWhat = this.appService.getUserRolesForPage(PAGE_NAMES.Leads);
    this.appService.getAllLeads$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (response.leads) {
          this.leadsData = [...response.leads];
          this.leadsTotalCount = response.totalCount;
          console.log(response);
        }
      });
    this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
  }

  onDelete(event: any) {
    this.leadsApi.deleteLead(event)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.appService.fetchAllLeads({
          SavedSearchId: this.savedSearchId,
          ...this.loadEvent
        });
      })
  }

  onEdit(event: any) {
    const selectedLead: ILead = event;
    const dialogRef = this.modalService.open(AddLeadModal, { size: 'lg' });
    dialogRef.componentInstance.iId = selectedLead.id;
  }

  getRowLink(row: any) {
    return `/pages/lead-details/${row.id}`;
  }


  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    console.log(event);
    this.appService.fetchAllLeads({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    });
  }

  onAddNew() {
    const dialogRef = this.modalService.open(AddLeadModal, { size: 'lg', backdrop: 'static' });
    dialogRef.componentInstance.oSaveClicked.subscribe(() => {
      this.appService.fetchAllLeads({
        SavedSearchId: this.savedSearchId,
        ...this.loadEvent
      });
    })
  }

  exportLeads() {
    this.spinner.show();
    this.leadsApi.exportLeads({
      SavedSearchId: this.savedSearchId,
      ...this.loadEvent
    }).then((x: any) => {
      var newBlob = new Blob([x], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement('a');
      link.href = data;
      link.download = "leads.xlsx";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      this.spinner.hide();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  }
}
