<div class="modal-box" cdkDrag>
    <form [formGroup]="addTaskForm" (ngSubmit)="onSubmit()">
        <div class="modal-header" fxLayout="row" fxLayoutAlign="space-between center" style="cursor: move;" cdkDragHandle>
            <h4 class="modal-title">{{ id === null ? 'Add' :'Edit'}} Task</h4>
            <div>
                <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body">
            <div class="row mb-3">
                <div fxLayout="column" fxLayoutAlign="center center" style="width: 10%">
                    <label for="title">Title</label>
                </div>
                <div style="width: 55%">
                    <input type="text" class="form-control" formControlName="title"
                    [ngClass]="{ 'is-invalid': submitted && f.title.errors }" placeholder="Enter task title">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">title is required</div>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="center center" style="width: 35%">
                    <select formControlName="priority" style="color: #f39437 !important; background-color: #fdf2be; border-radius: 50px; padding: 1px 1px 1px 20px; border: 0px; min-width: 145px !important; text-align: start;">
                        <option *ngFor="let p of TASK_PRIORITY" [ngValue]="p.value">{{p.title}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12">
                    <textarea type="text" class="form-control" rows="3" formControlName="description"
                        [ngClass]="{ 'is-invalid': submitted && f.description.errors }"
                        placeholder="enter task details"> </textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">text is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="clientId">Client</label>
                <div class="col-sm-9">
                    <ng-select placeholder="None" [items]="allClients" bindLabel="fullName" bindValue="id"
                        formControlName="clientId">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="campaignId">Campaign</label>
                <div class="col-sm-9">
                    <ng-select placeholder="None" [items]="fiteredCampaigns" bindLabel="name" bindValue="id"
                        formControlName="campaignId">
                    </ng-select>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="dueDate">Due Date</label>
                <div class="col-sm-9">
                    <input [ngClass]="{ 'is-invalid': submitted && f.dueDate.errors }" mwlFlatpickr
                        [altInputClass]="'datetime'" [altInput]="true" [convertModelValue]="true" formControlName="dueDate"
                        class="form-control" />
                    <div *ngIf="submitted && f.dueDate.errors" class="invalid-feedback">
                        <div *ngIf="f.dueDate.errors.required">required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-3" for="assignedUserId">Assigned User</label>
                <div class="col-sm-9">
                    <ng-select placeholder="None" [items]="allUsers" bindLabel="fullName" bindValue="id"
                        formControlName="assignedUserId">
                    </ng-select>
                </div>
            </div>
        </div>
        <div *ngIf="iCanEdit" class="modal-footer" style="cursor: move; justify-content: space-between;" cdkDragHandle>
            <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                <div *ngIf="iCanEdit">
                    <button class="btn btn-warning" style="box-shadow: none !important;" type="submit">Save</button>                    
                </div>
                <button *ngIf="id!==null && iCanDelete" type="button" class="btn btn-orange" (click)="deleteTask()">Delete</button>
                <button *ngIf="id!==null && (f.isCompleted.value === null || f.isCompleted.value === false)" type="button"
                class="blue-button" (click)="markAsComplete()">MARK AS COMPLETE</button>
            </div>
        </div>
    </form>
</div>