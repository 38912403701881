import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { WidgetComponent } from './widget/widget.component';
import { DashboardApiService } from './dashboard.apiservice';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { SortablejsModule } from 'ngx-sortablejs'
import { AddWidgetItemComponent } from './add-widget-item/add-widget-item.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgSelectModule } from '@ng-select/ng-select';
import { DatatableModule } from "../../shared/components/datatable/datatable.module";
import { TasksDatatableModule } from "../../shared/components/tasks-datatable/tasks-datatable.module";

@NgModule({
    declarations: [
        DashboardComponent,
        WidgetComponent,
        AddWidgetItemComponent
    ],
    providers: [DashboardApiService],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        DragDropModule,
        MatCardModule,
        SortablejsModule,
        MatCheckboxModule,
        NgSelectModule,
        DatatableModule,
        TasksDatatableModule
    ]
})
export class DashboardModule { }
