import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IWidget } from '../../core/models/widget.model';
import { AppService } from '../../store/app.service';
import { Options, SortableEvent } from 'sortablejs';
import { IUser } from '../../core/models/auth.models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as util from '../../core/helpers/utility';
import { UtilityService } from '../../core/helpers/utility.service';
import { IColumn, LoadDataFromServer } from '../../shared/components/datatable/datatable.model';
import { ROLES, ROLES_TYPE } from '../../core/helpers/constants';
import { ITask } from '../../core/models/tasks.models';
import { TasksApiService } from '../tasks/tasks.apiservice';
import { TasksService } from '../tasks/tasks.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 *  Dashboard Component
 */
export class DashboardComponent implements OnInit {
  // Default component attributes
  activeUser: IUser = {} as IUser;
  public userRoles: Array<number> = [];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private loadEvent!: LoadDataFromServer;
  columns: Array<IColumn> = [];
  ROLES = ROLES;
  todoTasksData: Array<ITask> = new Array<ITask>();
  dataTotalCount: number = 0;
  changeLayoutStarted = false;


  // iFrame
  iframeSrc: SafeResourceUrl= '';

  // Credentials for the iframe
  isAdmin: boolean = false;
  externalUser: boolean = false;
  token: string = '';
  id: any = '';

  // Styles
  getHeight: string = '85vh';

  // Tabs
  tabs = ['Tasks', 'Dashboard', 'Widgets'];
  activeTab = this.tabs[1];
  tabNumber = 1;

  widgets!: IWidget[];
  sortableOptions: Options = {
    group: 'test',
    handle: '.draghandler',
    onUpdate:(event) =>  this.onWidgetDrop(event),
    dataIdAttr: 'id'
  };

  constructor(private appService: AppService, private sanitizer: DomSanitizer, public uService: UtilityService, private tasksService: TasksService, private taskApiService: TasksApiService,) {
    this.userRoles = [...this.uService.getUserRoles()];
    this.appService.getActiveUser$()
    .pipe(takeUntil(this.destroyed$)
    ).subscribe(u => {
      this.activeUser = u;
    })


    this.columns.push({ title: 'Title', field: 'title', sortable: true, searchable: true } as IColumn);
    this.columns.push({ title: 'Client', field: 'clientName', sortable: true, searchable: true, anchorLink: true, anchorType: 'client', anchorParamsField: 'clientId' } as IColumn);
    this.columns.push({ title: 'Campaign', field: 'campaignName', sortable: true, searchable: true, anchorLink: true, anchorType: 'campaign', anchorParamsField: 'campaignId' } as IColumn);
    this.columns.push({ title: 'Due Date', field: 'dueDate', sortable: true, pipe: 'P_AppDateFormat', pipeArgs: ['MM/DD/YYYY'] } as IColumn);
    this.columns.push({ title: 'Assigned User', field: 'assignedUserName', sortable: true, searchable: true, visible: (this.userRoles.includes(ROLES.Admin.Code)) || this.activeUser.type === ROLES_TYPE.Internal } as IColumn);
  }

  ngOnInit(): void {
    this.token = util.getAuthToken();
    this.id = this.activeUser.id;

    this.appendTokenToIframeSrc();

    this.appService.setPageTitle$('');
    this.appService.getWidgets$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IWidget[]) => {
        this.widgets = response.sort((a, b) => a.order - b.order);
      });
    this.appService.fetchWidgets();

    this.appService.getAllTasks$()
    .pipe(takeUntil(this.destroyed$)
    )
    .subscribe((response: any) => {
      this.todoTasksData = response.data;
      this.dataTotalCount = response.totalCount;
    });

    this.appService.getForceLoadingTasks()
    .pipe(takeUntil(this.destroyed$)).subscribe((res: Boolean) => {
      if (res) {
        this.loadData();
        this.appService.setForceLoadingTasks(false);
      }
    })

    this.configureTasksService();
  }

  selectTab(tab: string) {
    this.activeTab = tab;
    this.tabNumber = this.tabs.indexOf(tab);
    if (this.isAdmin) {
      if (this.activeTab === 'DMS Dash') {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}`);
      }
      else {
        this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://prospects.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}&role=${this.userRoles[0]}`);
      }
    }
  }

  onCheckBoxChange(params: any) {
    this.taskApiService.markTask({ id: params.data.id, isCompleted: params.evt.currentTarget.checked })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  appendTokenToIframeSrc(): void {
    if (this.userRoles.includes(1) || this.userRoles.includes(10) || this.userRoles.includes(2)) {
      if (this.userRoles.includes(1) || this.userRoles.includes(2)) {
        this.activeTab = this.tabs[0];
        this.tabNumber = 0;
        this.isAdmin = true;
        this.tabs = ['Tasks', 'Sales Pipeline', 'DMS Dash', 'Widgets'];
      }
      // Bypass security check for this URL
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://prospects.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}&role=${this.userRoles[0]}`);
    }
    else if (this.userRoles.includes(3)) {
      // Bypass security check for this URL
      this.iframeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(`https://dms.reach.marketing/inside/?authToken=${this.token}&userId=${this.id}`);
    }
    // || this.userRoles.includes(2)
    else if (this.userRoles.includes(4) || this.userRoles.includes(12)) {
      this.tabs = ['Tasks', 'Widgets'];
      this.activeTab = this.tabs[0];
    }
    else {
      this.externalUser = true;
    }
  }

  onWidgetDrop(event: SortableEvent) {
    const widgetId = event.item.id;
    const newOrder = event.newIndex! + 1;
    this.appService.reorderWidget(widgetId, newOrder);
  }

  getLoadingParameters() {
    var yourDate = new Date();
    const offset = yourDate.getTimezoneOffset();
    yourDate = new Date(yourDate.getTime() + (offset*60*3429))
    let todayAtUserComputer = yourDate.toISOString().split('T')[0];
    return {
      savedSearchId: null,
      showOnlyMyTasks: true,
      dueDate: 'Overdue',
      completion: 'ToDo',
      todayAtUserComputer,
      ...this.loadEvent
    }
  }

  editRow(evt: any) {
    evt.allTasksloadingParameters = this.getLoadingParameters();
    this.tasksService.openEditTaskModal(evt);
  }

  onLoadingData(event: LoadDataFromServer) {
    this.loadEvent = event;
    this.loadData();
  }

  deleteRows(evt: any) {
    this.taskApiService.deleteTask(evt.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.loadData();
      })
  }

  loadData() {
    this.appService.fetchAllTasks(this.getLoadingParameters());
  }

  configureTasksService() {
    this.tasksService.onSave.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onDelete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
    this.tasksService.onMarkAsComplete.pipe(takeUntil(this.destroyed$))
      .subscribe((v: any) => {
        if (v) {
          this.loadData();
        }
      });
  }

  // addWidgetClick() {
  //   this.appService.addWidget$();
  // }

  // changeLayoutClick() {
  //   this.changeLayoutStarted = !this.changeLayoutStarted;
  //   this.appService.changeLayout(this.changeLayoutStarted);
  // }

  // changeLayoutText() { return this.changeLayoutStarted ? 'Finish Change Layout' : 'Change Layout'; }

}