import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlobalSearchComponent } from './global-search.component';
import { GlobalSearchApiService } from './global-search.apiservice';
import {MatCardModule} from '@angular/material/card';
import { DatatableModule } from '../../shared/components/datatable/datatable.module';


@NgModule({
  declarations: [
    GlobalSearchComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    DatatableModule
  ],
  providers: [GlobalSearchApiService]
})
export class GlobalSearchModule { }
