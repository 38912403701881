<ul *ngIf="!externalUser" class="tab-titles" style="border-bottom: 2px solid white;">
    <li *ngFor="let tab of tabs; let t = index;" (click)="selectTab(tab)" [ngStyle]="{'margin-left' : (t !== 0) ? '-15px' : '0px', 'z-index' : (activeTab === tab) ? '5' : '0'}" [ngClass]="(activeTab === tab) ? 'activeLI' : 'inactiveLI'"><h5 style="margin-bottom: 0px !important;">{{ tab }}</h5></li>
</ul>
<div style="background-color: white; padding-top: 16px; padding-bottom: 8px; padding-left: 8px; padding-right: 8px;" [ngStyle]="{'display': ((activeTab === 'Tasks') && !externalUser) ? 'block' : 'none'}">
    <div fxLayout="row" fxLayoutAlign="space-between" style="padding-left: 16px;">
        <h2>TO-DO</h2>
    </div>
    <div class="card-body">
        <app-tasks-datatable [iSortColumn]="'title'" [iShowActions]="userRoles.includes(ROLES.Admin.Code)"
            (oRowClick)="editRow($event)" [iUserRoles]="userRoles" (oCBClick)="onCheckBoxChange($event)"
            [iHideHeaderActions]="true" [iColumns]="columns" (oDeleteRows)="deleteRows($event)"
            (oEditRow)="editRow($event)" [iData$]="todoTasksData" [iIsServerLoading]="true" [iTotalCount]="dataTotalCount" 
            (oLoadDataFromServer)="onLoadingData($event)"></app-tasks-datatable>
    </div>
</div>
<div style="background-color: white;">
    <iframe *ngIf="((activeTab === 'Dashboard') && !externalUser) || (activeTab === 'Sales Pipeline') || (activeTab === 'DMS Dash')"
    [style.height]="getHeight" style='width: 100%;'[src]="iframeSrc" scrolling="yes"></iframe>
</div>
<!-- <div *ngIf="(activeTab === 'Widgets') || externalUser" [sortablejs]="widgets" [sortablejsOptions]="sortableOptions" fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" style="background-color: transparent; padding: 8px; margin-bottom: 16px;">
        <button type="button" class="blue-button" style="margin-right: 16px; margin-bottom: 16px;"
            (click)="addWidgetClick()">Add
            Widget</button>
        <button type="button" class="blue-button"
            (click)="changeLayoutClick()">{{changeLayoutText()}}</button>
    </div>
    <div class="d-flex flex-wrap" style="justify-content: start;">
        <ng-container *ngFor="let widget of widgets;">
            <app-widget [widget]="widget" [id]='widget.id' style="min-width: 400px;"></app-widget>
        </ng-container>
    </div>
</div> -->
<div style='margin-top: 20px;' *ngIf="(activeTab === 'Widgets') || externalUser" class="d-flex flex-wrap" [sortablejs]="widgets" [sortablejsOptions]="sortableOptions">
    <ng-container *ngFor="let widget of widgets;">
        <app-widget [widget]="widget" [id]='widget.id' style="min-width: 400px;"></app-widget>
    </ng-container>
</div>