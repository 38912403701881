import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ICampaign } from '../../../core/models/campaigns.model';
import { ILead } from '../../../core/models/lead.models';
import { CampaignsApiService } from '../../../pages/campaigns/campaigns.apiservice';
import { LeadsApiService } from '../../../pages/leads/leads.apiservice';
import { AppService } from '../../../store/app.service';
import { LoadDataFromServer } from '../../../shared/components/datatable/datatable.model';


@Component({
  selector: 'modal-add-lead',
  templateUrl: './add-lead.component.html',
  styleUrls: ['./add-lead.component.scss']
})
export class AddLeadModal implements OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Output() oSaveClicked = new EventEmitter<any>();

  allCampaigns: Array<ICampaign> = new Array<ICampaign>();

  addLeadForm!: FormGroup;
  submitted = false;
  id: string = '';
  savedSearchId!: string;
  private loadEvent!: LoadDataFromServer;

  @Input() set iId(value: string) {
    this.id = value;
    if (value !== null) {
      this.loadDetails();
    }
  }

  constructor(
    private campaignApiService: CampaignsApiService,
    private leadsApiService: LeadsApiService,
    private appService: AppService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {
    this.addLeadForm = this.formBuilder.group({
      id: [null],
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email]],
      phone: [null],
      campaignId: ['', [Validators.required]],
      referralId: [null],
      referralSource: [''],
    });
  }
  get f() { return this.addLeadForm.controls; }

  loadDetails() {
    this.leadsApiService.getLead(this.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.addLeadForm.patchValue(response.lead);
      });
  }

  updateData(data: ILead) {
    this.addLeadForm.patchValue(data);
  }

  ngAfterViewInit() {
    this.campaignApiService.getAllCampaigns({ })
      .pipe(takeUntil(this.destroyed$)
      )
      .subscribe((response: any) => {
        if (response.good) {
          this.allCampaigns = [...response.data];
          //   this.addClientForm.patchValue({ salesRep: response.data[0] });
        }
      });
      //this.savedSearchId = this.route.snapshot.queryParams['savedsearchid'];
  }

  onSubmit() {
    this.submitted = true;
    if (this.addLeadForm.invalid)
      return;
    // if (this.form.id.value === null)

    const request = {
      id: this.id,
      ...this.addLeadForm.getRawValue()
    };
    this.leadsApiService.addEdit(request).subscribe((response: any) => {
      if (response.good) {
        this.activeModal.close();
        this.oSaveClicked.emit();
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
