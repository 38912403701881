<form style="background-color: white;" [formGroup]="reportForm" (ngSubmit)="onViewReportSubmit()">
  <div fxLayout="row wrap" fxLayoutAlign="start center" style="padding: 16px 8px 16px 8px;">
    <div class="col-auto" style="margin-right: 16px;">
      <label class="mb-2">Please select report period:</label>
    </div>
    <div class="col-auto" style="margin-right: 16px;">
      <select class="form-select mb-2" aria-label="Please select period." (change)="onReportPeriodChange(t.value)" #t>
        <option selected disabled>Please select period.</option>
        <option type="button" ngbDropdownItem *ngFor="let p of ReportingPeriods"
          [selected]="selectedReportPeriod==p.key">
          {{ p.key }}
        </option>
      </select>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="start center" style="width: auto !important;">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="col-auto" style="margin-right: 16px;">
          <label class="sr-only" for="startDate">Start</label>
          <input type="date" [readonly]="datesReadonly" formControlName="startDate"
            class="form-control form-date date-custom mb-2"
            [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.startDate.errors }" />
          <div *ngIf="reportformsubmitted && reportForm.controls.startDate.errors" class="invalid-feedback">
            <div *ngIf="reportForm.controls.startDate.errors.required">Please select period.</div>
          </div>
        </div>
        <div class="col-auto" style="margin-right: 16px;">
          <label class="mb-2">-</label>
        </div>
        <div class="col-auto" style="margin-right: 16px;">
          <label class="sr-only" for="stopDate">End</label>
          <input type="date" [readonly]="datesReadonly" formControlName="stopDate"
            class="form-control form-date date-custom mb-2"
            [ngClass]="{ 'is-invalid': reportformsubmitted && reportForm.controls.stopDate.errors }"
            formControlName="stopDate" />
          <div *ngIf="reportformsubmitted && reportForm.controls.stopDate.errors" class="invalid-feedback">
            <div *ngIf="reportForm.controls.stopDate.errors.required">Please select period.</div>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <button type="submit" class="btn btn-outline-dark px-3 mb-2" title="View Report">
          View
        </button>
        &nbsp;
        <a [href]="ReportLink" target="_blank" class="btn btn-outline-dark px-4 mb-2" title="Open Report on New Page">
          <i class="fas fa-external-link-alt"></i>
        </a>
      </div>
    </div>
  </div>
</form>
<div *ngIf="this.invalidCampaignId" style="background-color: white; text-align: center; padding: 16px;">
  <h3>No valid platform campaign id provided.</h3>
</div>
<ng-container *ngIf="DisplayReport">
  <div>
    <ngx-ui-loader [loaderId]="'loader-report'"></ngx-ui-loader>
    <iframe width="100%" height="800" [src]="reportUrl" (load)="onFrameLoadComplete($event)"></iframe>
  </div>
</ng-container>